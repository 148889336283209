export type IconType = keyof typeof PathLookup

export interface IIconProps {
  type: IconType
  height?: number
  width?: number
  color?: string
  fillColor?: string
  onClick?: () => void
  title?: string
  viewBox?: string
  isDisabled?: boolean
}
export const Icon: React.FC<IIconProps> = ({
  type,
  height,
  width,
  color,
  fillColor,
  onClick,
  title,
  viewBox,
  isDisabled
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : undefined,
        display: 'flex',
        opacity: isDisabled ? 0.5 : 1
      }}
      title={title}
    >
      <svg
        viewBox={viewBox || `0 0 26 26`}
        style={{ color: color, height, width }}
        fill={fillColor || 'none'}
      >
        {PathLookup[type]}
      </svg>
    </div>
  )
}

const PathLookup = {
  Add: (
    <>
      <path
        d="M1.25 12H23.75"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 0.75V23.25"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  AddNote: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.879 23.5H2.5C1.67157 23.5 1 22.8284 1 22V2.5C1 1.67157 1.67157 1 2.5 1H22C22.8284 1 23.5 1.67157 23.5 2.5V13.879C23.4999 14.2765 23.342 14.6578 23.061 14.939L14.939 23.061C14.6578 23.342 14.2765 23.4999 13.879 23.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 23.365V16C14.5 15.1716 15.1716 14.5 16 14.5H23.365"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 8.51001H8.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 4.76001V12.26"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Tasks: (
    <>
      <path
        d="M5.5 11.01H14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 14.76H14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 18.51H14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 5.01001H17.5C18.3284 5.01001 19 5.68158 19 6.51001V22.26C19 23.0884 18.3284 23.76 17.5 23.76H2.5C1.67157 23.76 1 23.0884 1 22.26V6.51001C1 5.68158 1.67157 5.01001 2.5 5.01001H6.25C6.25 2.93894 7.92893 1.26001 10 1.26001C12.0711 1.26001 13.75 2.93894 13.75 5.01001Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 4.26001C10.2071 4.26001 10.375 4.4279 10.375 4.63501C10.375 4.84212 10.2071 5.01001 10 5.01001C9.79289 5.01001 9.625 4.84212 9.625 4.63501C9.625 4.4279 9.79289 4.26001 10 4.26001"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  ChevronDown: (
    <path
      d="M23.75 7.81097L13.03 18.53C12.8895 18.6707 12.6988 18.7497 12.5 18.7497C12.3012 18.7497 12.1105 18.6707 11.97 18.53L1.25 7.81097"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  ChevronUp: (
    <path
      d="M1.25 11.689L11.97 0.969998C12.1105 0.829303 12.3012 0.750244 12.5 0.750244C12.6988 0.750244 12.8895 0.829303 13.03 0.969998L23.75 11.689"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  Avatar: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 0.5C5.87258 0.5 0.5 5.87258 0.5 12.5C0.5 19.1274 5.87258 24.5 12.5 24.5C19.1274 24.5 24.5 19.1274 24.5 12.5C24.5 5.87258 19.1274 0.5 12.5 0.5ZM2 12.5C2 6.70101 6.70101 2 12.5 2C18.299 2 23 6.70101 23 12.5C23 15.6364 21.6248 18.4517 19.4445 20.3757C17.533 18.6888 15.0651 17.7499 12.5 17.7499C9.9349 17.7499 7.46703 18.6888 5.55547 20.3757C3.37517 18.4517 2 15.6364 2 12.5ZM6.77718 21.3048C8.4234 22.377 10.3889 23 12.5 23C14.6111 23 16.5766 22.377 18.2228 21.3048C16.6188 19.9822 14.5973 19.2499 12.5 19.2499C10.4027 19.2499 8.38122 19.9822 6.77718 21.3048ZM6.5 10.25C6.5 6.93629 9.18629 4.25 12.5 4.25C15.8137 4.25 18.5 6.93629 18.5 10.25C18.5 13.5637 15.8137 16.25 12.5 16.25C9.18629 16.25 6.5 13.5637 6.5 10.25ZM12.5 5.75C10.0147 5.75 8 7.76472 8 10.25C8 12.7353 10.0147 14.75 12.5 14.75C14.9853 14.75 17 12.7353 17 10.25C17 7.76472 14.9853 5.75 12.5 5.75Z"
      fill="currentColor"
      fillOpacity="0.87"
    />
  ),
  CancelClose: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7803 1.77935C24.0732 1.48646 24.0732 1.01159 23.7803 0.718693C23.4874 0.4258 23.0126 0.4258 22.7197 0.718693L12 11.4384L1.28033 0.718693C0.987437 0.4258 0.512563 0.4258 0.21967 0.718693C-0.0732233 1.01159 -0.0732233 1.48646 0.21967 1.77935L10.9393 12.499L0.21967 23.2187C-0.0732233 23.5116 -0.0732233 23.9865 0.21967 24.2794C0.512563 24.5722 0.987437 24.5722 1.28033 24.2794L12 13.5597L22.7197 24.2794C23.0126 24.5722 23.4874 24.5722 23.7803 24.2794C24.0732 23.9865 24.0732 23.5116 23.7803 23.2187L13.0607 12.499L23.7803 1.77935Z"
      fill="currentColor"
      fillOpacity="0.87"
    />
  ),
  CreateTask: (
    <>
      <path
        d="M5.25 11.011H10.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 14.761H8.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 18.511H8.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 23.761H2.25C1.42157 23.761 0.75 23.0894 0.75 22.261V6.51099C0.75 5.68256 1.42157 5.01099 2.25 5.01099H6C6 2.93992 7.67893 1.26099 9.75 1.26099C11.8211 1.26099 13.5 2.93992 13.5 5.01099H17.25C18.0784 5.01099 18.75 5.68256 18.75 6.51099V8.76099"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 4.26099C9.95711 4.26099 10.125 4.42888 10.125 4.63599C10.125 4.84309 9.95711 5.01099 9.75 5.01099C9.54289 5.01099 9.375 4.84309 9.375 4.63599C9.375 4.42888 9.54289 4.26099 9.75 4.26099"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 23.761C20.5637 23.761 23.25 21.0747 23.25 17.761C23.25 14.4473 20.5637 11.761 17.25 11.761C13.9363 11.761 11.25 14.4473 11.25 17.761C11.25 21.0747 13.9363 23.761 17.25 23.761Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 14.761V20.761"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 17.761H20.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  DocumentVault: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3034 12.435C23.3587 12.1419 23.2854 11.8385 23.1036 11.6076C22.9217 11.3767 22.6502 11.2426 22.3635 11.242H11.0078C10.5522 11.2411 10.1599 10.9069 10.0688 10.442L9.80192 9.05098C9.71081 8.58605 9.31854 8.25183 8.86297 8.25098H2.67722C2.39067 8.25193 2.1195 8.38616 1.93782 8.61699C1.75615 8.84782 1.68297 9.15111 1.73827 9.44398L4.22774 22.444C4.31885 22.9089 4.71112 23.2431 5.16669 23.244H20.4491C20.9047 23.2431 21.297 22.9089 21.3881 22.444C21.7827 20.392 22.8359 14.869 23.3034 12.435Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.32031 5.25L5.33951 1.75C5.33951 1.19772 5.76935 0.75 6.29959 0.75H18.745C19.2752 0.75 19.7051 1.19772 19.7051 1.75L19.7243 8.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.21204 3.74219L16.1211 3.75019"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5208 6.75H16.1211"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Down: (
    <path
      d="M12.5906 17.8858L7.2829 12.5781L7.9906 11.8704L12.0906 15.9704V6.07812H13.0906V15.9704L17.1906 11.8704L17.8983 12.5781L12.5906 17.8858Z"
      stroke="currentColor"
      strokeWidth="1"
    />
  ),
  Up: (
    <path
      d="M11.5001 17.3077V7.4154L7.40008 11.5154L6.69238 10.8077L12.0001 5.5L17.3078 10.8077L16.6001 11.5154L12.5001 7.4154V17.3077H11.5001Z"
      stroke="currentColor"
      strokeWidth="1"
    />
  ),
  PushPin: (
    <path
      d="M8.5 8.1V8.30711L8.64645 8.45355L10.3 10.1071V11.2H6.3H5.8V11.7V16.8929L5.4 17.2929L5 16.8929V11.7V11.2H4.5H0.5V10.1071L2.15355 8.45355L2.3 8.30711V8.1V1.8V1.3H1.8H1.4V0.5H9.4V1.3H9H8.5V1.8V8.1Z"
      stroke="currentColor"
      strokeWidth="1"
    />
  ),
  ExpandAccount: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4375 4C25.4375 2.34315 24.0944 1 22.4375 1H4.4375C2.78065 1 1.4375 2.34315 1.4375 4V22C1.4375 23.6569 2.78065 25 4.4375 25H22.4375C24.0944 25 25.4375 23.6569 25.4375 22V4Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8661 13H6.58034"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5803 8.71429L16.8661 13L12.5803 17.2857"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2946 7.85712V18.1428"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  CollapseAccount: (
    <>
      <path
        d="M9.57141 13.497H19.8571"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8571 9.2113L9.57141 13.497L13.8571 17.7827"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4.49701C1 2.84016 2.34315 1.49701 4 1.49701H22C23.6569 1.49701 25 2.84016 25 4.49701V22.497C25 24.1539 23.6569 25.497 22 25.497H4C2.34315 25.497 1 24.1539 1 22.497V4.49701Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.14288 8.35413V18.6398"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Favorite: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2925 1.77566L16.1124 9.24221H21.6022C22.1695 9.2199 22.6896 9.55703 22.9021 10.0847C23.1147 10.6125 22.9738 11.2171 22.5501 11.5959L17.7923 14.9256L20.4311 21.0052C20.6674 21.576 20.5147 22.2342 20.0513 22.6418C19.588 23.0494 18.9172 23.1158 18.3834 22.8067L11.9987 19.2092L5.61776 22.8067C5.08396 23.1158 4.41308 23.0494 3.94978 22.6418C3.48647 22.2342 3.33375 21.576 3.57007 21.0052L6.21066 14.9256L1.45284 11.5959C1.02782 11.2187 0.885269 10.6143 1.09673 10.0861C1.3082 9.558 1.82801 9.22017 2.39526 9.24221H7.88505L10.7105 1.77566C10.9651 1.29815 11.4614 1 12.0015 1C12.5416 1 13.0378 1.29815 13.2925 1.77566Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  Delete: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 21H6.75C5.92157 21 5.25 20.3284 5.25 19.5V6H18.75V19.5C18.75 20.3284 18.0784 21 17.25 21Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 16.5V10.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 16.5V10.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 6H21.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 3H9.75C8.92157 3 8.25 3.67157 8.25 4.5V6H15.75V4.5C15.75 3.67157 15.0784 3 14.25 3Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Success: (
    <>
      <path
        d="M6 13.223L8.45 16.7C8.63855 16.9817 8.95158 17.1549 9.29037 17.165C9.62916 17.1751 9.95197 17.0209 10.157 16.751L18 6.828"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23.249C18.2132 23.249 23.25 18.2122 23.25 11.999C23.25 5.78582 18.2132 0.749023 12 0.749023C5.7868 0.749023 0.75 5.78582 0.75 11.999C0.75 18.2122 5.7868 23.249 12 23.249Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Failure: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23.249C18.2132 23.249 23.25 18.2122 23.25 11.999C23.25 5.78582 18.2132 0.749023 12 0.749023C5.7868 0.749023 0.75 5.78582 0.75 11.999C0.75 18.2122 5.7868 23.249 12 23.249Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 16.499L16.5 7.49902"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 16.499L7.5 7.49902"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Info: (
    <>
      <path
        d="M14.75 17H14C13.1716 17 12.5 16.3284 12.5 15.5V11.75C12.5 11.3358 12.1642 11 11.75 11H11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.125 7.25C11.9179 7.25 11.75 7.41789 11.75 7.625C11.75 7.83211 11.9179 8 12.125 8C12.3321 8 12.5 7.83211 12.5 7.625C12.5 7.41789 12.3321 7.25 12.125 7.25V7.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 23.75C18.7132 23.75 23.75 18.7132 23.75 12.5C23.75 6.2868 18.7132 1.25 12.5 1.25C6.2868 1.25 1.25 6.2868 1.25 12.5C1.25 18.7132 6.2868 23.75 12.5 23.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Warning: (
    <>
      <path
        d="M12.5 19.25C12.2929 19.25 12.125 19.4179 12.125 19.625C12.125 19.8321 12.2929 20 12.5 20C12.7071 20 12.875 19.8321 12.875 19.625C12.875 19.4179 12.7071 19.25 12.5 19.25V19.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 16.25V8.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.121 2.26001C13.8174 1.64189 13.1887 1.25024 12.5 1.25024C11.8114 1.25024 11.1826 1.64189 10.879 2.26001L1.40601 21.558C1.17419 22.0296 1.20221 22.5875 1.48014 23.0334C1.75807 23.4794 2.24652 23.7504 2.77201 23.75H22.228C22.7535 23.7504 23.242 23.4794 23.5199 23.0334C23.7978 22.5875 23.8258 22.0296 23.594 21.558L14.121 2.26001Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Location: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0531 7.99996C10.9942 7.99996 10.1328 8.96612 10.1328 10.155C10.1328 11.3427 10.9942 12.3077 12.0531 12.3077C13.1119 12.3077 13.9733 11.3427 13.9733 10.155C13.9733 8.96612 13.1119 7.99996 12.0531 7.99996ZM12.0531 14.1538C10.0868 14.1538 8.48694 12.3606 8.48694 10.155C8.48694 7.94827 10.0868 6.15381 12.0531 6.15381C14.0194 6.15381 15.6192 7.94827 15.6192 10.155C15.6192 12.3606 14.0194 14.1538 12.0531 14.1538Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.052 1.84615C7.96796 1.84615 4.64542 5.60862 4.64542 10.2314C4.64542 16.1132 10.8165 21.8437 12.052 22.1489C13.2875 21.8425 19.4586 16.112 19.4586 10.2314C19.4586 5.60862 16.1361 1.84615 12.052 1.84615ZM12.052 24C10.0835 24 2.99951 17.1668 2.99951 10.2314C2.99951 4.58954 7.06052 0 12.052 0C17.0435 0 21.1045 4.58954 21.1045 10.2314C21.1045 17.1668 14.0205 24 12.052 24Z"
        fill="currentColor"
      />
    </>
  ),
  Meatball: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8 24C13.3464 24 14.6 22.7464 14.6 21.2C14.6 19.6536 13.3464 18.4 11.8 18.4C10.2536 18.4 9 19.6536 9 21.2C9 22.7464 10.2536 24 11.8 24Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8 5.6C13.3464 5.6 14.6 4.3464 14.6 2.8C14.6 1.2536 13.3464 0 11.8 0C10.2536 0 9 1.2536 9 2.8C9 4.3464 10.2536 5.6 11.8 5.6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8 14.8C13.3464 14.8 14.6 13.5464 14.6 12C14.6 10.4536 13.3464 9.2 11.8 9.2C10.2536 9.2 9 10.4536 9 12C9 13.5464 10.2536 14.8 11.8 14.8Z"
        fill="currentColor"
      />
    </>
  ),
  Email: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 7.1499C1.5 6.32148 2.17157 5.6499 3 5.6499H21C21.8284 5.6499 22.5 6.32148 22.5 7.1499V19.1499C22.5 19.9783 21.8284 20.6499 21 20.6499H3C2.17157 20.6499 1.5 19.9783 1.5 19.1499V7.1499Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.161 6.19989L14.017 12.4639C12.8279 13.3786 11.1721 13.3786 9.98299 12.4639L1.83899 6.19989"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Expand: (
    <>
      <path
        d="M6.9375 8.49799L9.9375 5.49799L6.9375 2.49799"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6875 0.997986H24.1875"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6875 5.49799H24.1875"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6875 9.99799H24.1875"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6875 14.498H24.1875"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6875 18.998H24.1875"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6875 5.49799H9.9375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Phone: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5401 23.2899L15.5521 23.2969C17.74 24.6899 20.602 24.376 22.4361 22.5419L23.2101 21.7679C23.9299 21.0472 23.9299 19.8796 23.2101 19.1589L19.9491 15.8999C19.2283 15.1801 18.0608 15.1801 17.3401 15.8999V15.8999C16.9944 16.246 16.5252 16.4405 16.0361 16.4405C15.5469 16.4405 15.0778 16.246 14.7321 15.8999L9.51406 10.6809C8.79424 9.96018 8.79424 8.79262 9.51406 8.0719V8.0719C9.86018 7.7262 10.0547 7.25708 10.0547 6.7679C10.0547 6.27871 9.86018 5.80959 9.51406 5.4639L6.25406 2.1999C5.53334 1.48008 4.36578 1.48008 3.64506 2.1999L2.87106 2.9739C1.03734 4.80804 0.723096 7.66951 2.11506 9.8579L2.12306 9.8699C5.69647 15.1597 10.251 19.7153 15.5401 23.2899V23.2899Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  Profile: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 11.75C15.3995 11.75 17.75 9.39949 17.75 6.5C17.75 3.60051 15.3995 1.25 12.5 1.25C9.60051 1.25 7.25 3.60051 7.25 6.5C7.25 9.39949 9.60051 11.75 12.5 11.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 23.75C2.75 18.3652 7.11522 14 12.5 14C17.8848 14 22.25 18.3652 22.25 23.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  RelationshipView: (
    <>
      <path
        d="M3.75 19.748V19.748C3.90729 16.287 6.83336 13.6045 10.295 13.748H13.705C17.1666 13.6045 20.0927 16.287 20.25 19.748V19.748"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7.74799V19.748"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.74799C13.6569 7.74799 15 6.40484 15 4.74799C15 3.09113 13.6569 1.74799 12 1.74799C10.3431 1.74799 9 3.09113 9 4.74799C9 6.40484 10.3431 7.74799 12 7.74799Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 24.248C4.99264 24.248 6 23.2406 6 21.998C6 20.7553 4.99264 19.748 3.75 19.748C2.50736 19.748 1.5 20.7553 1.5 21.998C1.5 23.2406 2.50736 24.248 3.75 24.248Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24.248C13.2426 24.248 14.25 23.2406 14.25 21.998C14.25 20.7553 13.2426 19.748 12 19.748C10.7574 19.748 9.75 20.7553 9.75 21.998C9.75 23.2406 10.7574 24.248 12 24.248Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.25 24.248C21.4926 24.248 22.5 23.2406 22.5 21.998C22.5 20.7553 21.4926 19.748 20.25 19.748C19.0074 19.748 18 20.7553 18 21.998C18 23.2406 19.0074 24.248 20.25 24.248Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  SaveOkDone: (
    <path
      d="M1 12.3333L8.33333 19.6667L23 5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  ),
  Save: (
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      d="M20 7.42307V18.3846C20 18.8449 19.8458 19.2292 19.5375 19.5375C19.2292 19.8458 18.8449 20 18.3846 20H5.61538C5.15513 20 4.77083 19.8458 4.4625 19.5375C4.15417 19.2292 4 18.8449 4 18.3846V5.61537C4 5.15512 4.15417 4.77083 4.4625 4.4625C4.77083 4.15417 5.15513 4 5.61538 4H16.5769L20 7.42307ZM19 7.85L16.15 5H5.61538C5.43589 5 5.28846 5.05769 5.17308 5.17307C5.05769 5.28846 5 5.43589 5 5.61537V18.3846C5 18.5641 5.05769 18.7115 5.17308 18.8269C5.28846 18.9423 5.43589 19 5.61538 19H18.3846C18.5641 19 18.7115 18.9423 18.8269 18.8269C18.9423 18.7115 19 18.5641 19 18.3846V7.85ZM12 16.5385C12.5513 16.5385 13.0224 16.343 13.4135 15.9519C13.8045 15.5609 14 15.0898 14 14.5385C14 13.9872 13.8045 13.516 13.4135 13.125C13.0224 12.734 12.5513 12.5385 12 12.5385C11.4487 12.5385 10.9776 12.734 10.5865 13.125C10.1955 13.516 10 13.9872 10 14.5385C10 15.0898 10.1955 15.5609 10.5865 15.9519C10.9776 16.343 11.4487 16.5385 12 16.5385ZM6.76923 9.76922H14.1923V6.76922H6.76923V9.76922Z"
      fillRule="evenodd"
    />
  ),
  SimulateArrow: (
    <>
      <path
        d="M3.75 20L19.66 4.09"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.66 19.09L19.66 4.08997L4.66003 4.08997"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  ArrowLeft: (
    <>
      <path
        d="M23.75 12.5H1.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.75 2L1.25 12.5L11.75 23"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Settings: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4491 2.79705C11.8452 3.23672 12.4092 3.48775 13.001 3.48775C13.5927 3.48775 14.1567 3.23672 14.5528 2.79705L15.5625 1.68918C16.1554 1.03294 17.0972 0.820779 17.9143 1.15935C18.7313 1.49792 19.247 2.31398 19.202 3.19729L19.1262 4.69152C19.0966 5.28096 19.3176 5.85546 19.7346 6.27309C20.1516 6.69072 20.7258 6.91255 21.3153 6.88377L22.8095 6.80799C23.6922 6.76447 24.5069 7.28065 24.8447 8.09737C25.1824 8.91408 24.9701 9.85492 24.3144 10.4475L23.2023 11.4508C22.7632 11.8474 22.5126 12.4115 22.5126 13.0032C22.5126 13.5949 22.7632 14.1589 23.2023 14.5556L24.3144 15.5588C24.9706 16.1518 25.1828 17.0935 24.8442 17.9106C24.5057 18.7277 23.6896 19.2433 22.8063 19.1984L21.3121 19.1226C20.7213 19.0922 20.1453 19.3136 19.727 19.7319C19.3087 20.1502 19.0873 20.7262 19.1177 21.317L19.1935 22.8112C19.2326 23.6898 18.7177 24.499 17.9052 24.8358C17.0927 25.1725 16.1564 24.9648 15.5625 24.3161L14.5582 23.205C14.1617 22.766 13.5978 22.5154 13.0063 22.5154C12.4147 22.5154 11.8509 22.766 11.4544 23.205L10.4458 24.3161C9.85282 24.9679 8.91481 25.178 8.10046 24.8413C7.2861 24.5047 6.77022 23.6936 6.81057 22.8133L6.88742 21.3191C6.91781 20.7283 6.69639 20.1523 6.27809 19.734C5.85979 19.3157 5.28381 19.0943 4.69303 19.1247L3.1988 19.2005C2.31585 19.2466 1.49945 18.732 1.16012 17.9156C0.820794 17.0991 1.03199 16.1575 1.6875 15.5642L2.79856 14.5609C3.23768 14.1643 3.48827 13.6002 3.48827 13.0085C3.48827 12.4168 3.23768 11.8527 2.79856 11.4561L1.6875 10.4475C1.03418 9.85488 0.823306 8.91588 1.16048 8.10081C1.49766 7.28575 2.31028 6.77015 3.19133 6.81226L4.68556 6.88804C5.27748 6.91916 5.85478 6.69745 6.2737 6.27812C6.69263 5.85878 6.91378 5.28128 6.88208 4.68938L6.81057 3.19409C6.76925 2.31342 7.28479 1.50146 8.09938 1.16424C8.91398 0.827021 9.85257 1.037 10.4458 1.68918L11.4491 2.79705Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.001 17.8066C15.6536 17.8066 17.8039 15.6562 17.8039 13.0037C17.8039 10.3511 15.6536 8.20081 13.001 8.20081C10.3484 8.20081 8.19812 10.3511 8.19812 13.0037C8.19812 15.6562 10.3484 17.8066 13.001 17.8066Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  FileUpload: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.75 23.75C21.0637 23.75 23.75 21.0637 23.75 17.75C23.75 14.4363 21.0637 11.75 17.75 11.75C14.4363 11.75 11.75 14.4363 11.75 17.75C11.75 21.0637 14.4363 23.75 17.75 23.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 20.75V14.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 14.75L15.5 17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.75 14.75L20 17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 20.75H2.75C1.92157 20.75 1.25 20.0784 1.25 19.25V2.75C1.25 1.92157 1.92157 1.25 2.75 1.25H13.379C13.7765 1.25008 14.1578 1.40798 14.439 1.689L17.311 4.561C17.592 4.8422 17.7499 5.22345 17.75 5.621V8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Download: (
    <>
      <path
        d="M12.378 4.25V16.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.87799 11.75L12.378 16.25L16.878 11.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.628 16.25V17.75C23.628 19.4069 22.2848 20.75 20.628 20.75H4.12799C2.47114 20.75 1.12799 19.4069 1.12799 17.75V16.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  ExpandWidget: (
    <>
      <path
        d="M10.25 14.748L1.25 23.748"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.75 7.99799V1.24799H17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 16.998V23.748H8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.75 1.24799L14.75 10.248"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.75 14.748L23.75 23.748"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 7.99799V1.24799H8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.75 16.998V23.748H17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 1.24799L10.25 10.248"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  File: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.31 6.05905C23.591 6.34025 23.7489 6.7215 23.749 7.11905V21.5C23.7479 22.7415 22.7415 23.7475 21.5 23.748H3.50002C2.25777 23.7486 1.25013 22.7423 1.24902 21.5V3.50005C1.24849 2.90279 1.48545 2.32983 1.90768 1.90741C2.32991 1.48499 2.90277 1.24778 3.50002 1.24805H17.878C18.2759 1.24787 18.6576 1.40578 18.939 1.68705L23.31 6.05905Z"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.749 1.24805V5.74805C17.749 6.57647 18.4206 7.24805 19.249 7.24805H23.749"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Filter: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7 2.55403C23.7135 2.34703 23.6408 2.14364 23.4992 1.99212C23.3575 1.84059 23.1594 1.75441 22.952 1.75403H2C1.79246 1.75412 1.59424 1.84021 1.45249 1.9918C1.31075 2.1434 1.23816 2.34695 1.252 2.55403C1.61344 7.58679 5.28043 11.7639 10.224 12.774V22.649C10.2241 22.94 10.3925 23.2046 10.6559 23.328C10.9194 23.4513 11.2305 23.4112 11.454 23.225L14.454 20.725C14.6252 20.5828 14.7242 20.3716 14.724 20.149V12.774C19.6693 11.7657 23.3384 7.58813 23.7 2.55403Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  CustomFilter: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 20.5V15.5H12.5V17.5H20.5V18.5H12.5V20.5H11.5ZM3.5 18.5V17.5H8.5V18.5H3.5ZM7.5 14.5V12.5H3.5V11.5H7.5V9.5H8.5V14.5H7.5ZM11.5 12.5V11.5H20.5V12.5H11.5ZM15.5 8.5V3.5H16.5V5.5H20.5V6.5H16.5V8.5H15.5ZM3.5 6.5V5.5H12.5V6.5H3.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  FilterApplied: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.10001 16.9V15.6H9.47498V16.9H4.10001ZM4.10001 12.65V11.35H14.0827V12.65H4.10001ZM4.10001 8.40001V7.10004H19.9V8.40001H4.10001Z"
      stroke="currentColor"
      strokeWidth="1"
    />
  ),
  Pin: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 8.1V8.30711L8.64645 8.45355L10.3 10.1071V11.2H6.3H5.8V11.7V16.8929L5.4 17.2929L5 16.8929V11.7V11.2H4.5H0.5V10.1071L2.15355 8.45355L2.3 8.30711V8.1V1.8V1.3H1.8H1.4V0.5H9.4V1.3H9H8.5V1.8V8.1Z"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  Tag: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.30005 15.2306V1.77778C1.30005 1.41678 1.41878 1.12312 1.66354 0.86929C1.90777 0.616017 2.18258 0.5 2.51433 0.5H11.0858C11.4175 0.5 11.6923 0.616017 11.9366 0.86929C12.1813 1.12312 12.3 1.41678 12.3 1.77778V15.2306L7.00312 12.8764L6.80005 12.7862L6.59698 12.8764L1.30005 15.2306Z"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  MoreVertical: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19.2691C11.5875 19.2691 11.2344 19.1222 10.9407 18.8285C10.6469 18.5347 10.5 18.1816 10.5 17.7691C10.5 17.3566 10.6469 17.0035 10.9407 16.7098C11.2344 16.416 11.5875 16.2691 12 16.2691C12.4125 16.2691 12.7656 16.416 13.0594 16.7098C13.3531 17.0035 13.5 17.3566 13.5 17.7691C13.5 18.1816 13.3531 18.5347 13.0594 18.8285C12.7656 19.1222 12.4125 19.2691 12 19.2691ZM12 13.4999C11.5875 13.4999 11.2344 13.353 10.9407 13.0592C10.6469 12.7655 10.5 12.4124 10.5 11.9999C10.5 11.5874 10.6469 11.2343 10.9407 10.9405C11.2344 10.6468 11.5875 10.4999 12 10.4999C12.4125 10.4999 12.7656 10.6468 13.0594 10.9405C13.3531 11.2343 13.5 11.5874 13.5 11.9999C13.5 12.4124 13.3531 12.7655 13.0594 13.0592C12.7656 13.353 12.4125 13.4999 12 13.4999ZM12 7.73064C11.5875 7.73064 11.2344 7.58376 10.9407 7.29001C10.6469 6.99628 10.5 6.64316 10.5 6.23066C10.5 5.81818 10.6469 5.46506 10.9407 5.17131C11.2344 4.87758 11.5875 4.73071 12 4.73071C12.4125 4.73071 12.7656 4.87758 13.0594 5.17131C13.3531 5.46506 13.5 5.81818 13.5 6.23066C13.5 6.64316 13.3531 6.99628 13.0594 7.29001C12.7656 7.58376 12.4125 7.73064 12 7.73064Z"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  Folder: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.25 5.75C20.25 4.92157 19.5784 4.25 18.75 4.25H9L7.2 1.85C6.91672 1.47229 6.47214 1.25 6 1.25H2.25C1.42157 1.25 0.75 1.92157 0.75 2.75V16.25C0.75 17.0784 1.42157 17.75 2.25 17.75H18.75C19.5784 17.75 20.25 17.0784 20.25 16.25V5.75Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  ChartView: (
    <>
      <circle cx="12.5" cy="12" r="11.5" stroke="currentColor" />
      <path d="M12.4914 12.0596L23.6279 12.0595" stroke="currentColor" />
      <path
        d="M6.41455 21.4041L12.75 11.4483L12.75 0.587554"
        stroke="currentColor"
      />
    </>
  ),
  GraphView: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 7.6499C9.75 6.82147 10.4216 6.1499 11.25 6.1499H12.75C13.5784 6.1499 14.25 6.82148 14.25 7.6499V22.6499C14.25 23.4783 13.5784 24.1499 12.75 24.1499H11.25C10.4216 24.1499 9.75 23.4783 9.75 22.6499V7.6499Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 13.6499C18.75 12.8215 19.4216 12.1499 20.25 12.1499H21.75C22.5784 12.1499 23.25 12.8215 23.25 13.6499V22.6499C23.25 23.4783 22.5784 24.1499 21.75 24.1499H20.25C19.4216 24.1499 18.75 23.4783 18.75 22.6499V13.6499Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 19.6499C0.75 18.8215 1.42157 18.1499 2.25 18.1499H3.75C4.57843 18.1499 5.25 18.8215 5.25 19.6499V22.6499C5.25 23.4783 4.57843 24.1499 3.75 24.1499H2.25C1.42157 24.1499 0.75 23.4783 0.75 22.6499V19.6499Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  TableView: (
    <>
      <rect
        x="1"
        y="0.5"
        width="23"
        height="23"
        rx="1.5"
        stroke="currentColor"
      />
      <line
        x1="1.47607"
        y1="2.77271"
        x2="23.9258"
        y2="2.77271"
        stroke="currentColor"
      />
      <line
        x1="1.47607"
        y1="16.9545"
        x2="23.9258"
        y2="16.9545"
        stroke="currentColor"
      />
      <path d="M1.47607 9.81818H23.9258" stroke="currentColor" />
      <line x1="4.3418" y1="24" x2="4.3418" y2="1" stroke="currentColor" />
      <line x1="13.5" y1="24" x2="13.5" y2="1" stroke="currentColor" />
    </>
  ),
  HeadOfHousehold: (
    <>
      <path
        d="M0.75 17.25C0.751096 14.7562 2.12687 12.4661 4.32806 11.2941C6.52925 10.122 9.19729 10.2589 11.267 11.65"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 9C9.77817 9 11.625 7.15317 11.625 4.875C11.625 2.59683 9.77817 0.75 7.5 0.75C5.22183 0.75 3.375 2.59683 3.375 4.875C3.375 7.15317 5.22183 9 7.5 9Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.946 11.68L19.488 15H22.488C22.798 14.9878 23.0822 15.1716 23.1983 15.4593C23.3145 15.7469 23.2375 16.0765 23.006 16.283L20.406 18.845L21.849 22.159C21.9781 22.4701 21.8947 22.8289 21.6415 23.0511C21.3883 23.2733 21.0217 23.3095 20.73 23.141L17.243 21.18L13.757 23.141C13.4652 23.3102 13.098 23.2745 12.8444 23.0521C12.5908 22.8297 12.5073 22.4704 12.637 22.159L14.08 18.845L11.48 16.283C11.2489 16.0782 11.1707 15.7506 11.2844 15.4635C11.3981 15.1764 11.6793 14.9911 11.988 15H14.988L16.532 11.677C16.6712 11.4154 16.9436 11.2522 17.2399 11.2529C17.5362 11.2535 17.8079 11.4179 17.946 11.68Z"
        stroke="#F89938"
        fill="#F89938"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  InterestedParties: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 12C9.77817 12 11.625 10.1532 11.625 7.875C11.625 5.59683 9.77817 3.75 7.5 3.75C5.22183 3.75 3.375 5.59683 3.375 7.875C3.375 10.1532 5.22183 12 7.5 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 20.25C0.75 16.5221 3.77208 13.5 7.5 13.5C11.2279 13.5 14.25 16.5221 14.25 20.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.251 4.41602C15.9763 3.29498 18.2639 3.61677 19.613 5.17027C20.9621 6.72378 20.9601 9.03391 19.6083 10.5851C18.2565 12.1362 15.9684 12.4541 14.245 11.33"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 13.667C17.0024 13.2133 19.1025 13.6948 20.7074 14.9754C22.3123 16.256 23.2479 18.1968 23.25 20.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  SortColumn: (
    <>
      <path d="M5 1L2 4H8L5 1Z" fill="currentColor" stroke="currentColor" />
      <path d="M5 11L8 8L2 8L5 11Z" fill="currentColor" stroke="currentColor" />
    </>
  ),
  SortColumnAsc: (
    <>
      <path d="M5 1L2 4H8L5 1Z" fill="currentColor" stroke="currentColor" />
    </>
  ),
  SortColumnDesc: (
    <>
      <path d="M5 11L8 8L2 8L5 11Z" fill="currentColor" stroke="currentColor" />
    </>
  ),
  Interactions: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H23.25C24.0784 0.75 24.75 1.42157 24.75 2.25V23.25C24.75 24.0784 24.0784 24.75 23.25 24.75H2.25C1.42157 24.75 0.75 24.0784 0.75 23.25V2.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 20.75C17.1683 20.75 20.75 17.1683 20.75 12.75C20.75 8.33172 17.1683 4.75 12.75 4.75C8.33172 4.75 4.75 8.33172 4.75 12.75C4.75 17.1683 8.33172 20.75 12.75 20.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 12.75V7.95001"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 12.75H17.55"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Subtract: (
    <path
      d="M1.25 12.5381H23.75"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  More: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1089 13.8363C16.4755 13.8363 15.9578 13.3243 15.9578 12.6909C15.9578 12.0574 16.4652 11.5454 17.0975 11.5454H17.1089C17.7424 11.5454 18.2544 12.0574 18.2544 12.6909C18.2544 13.3243 17.7424 13.8363 17.1089 13.8363Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5158 13.8363C11.8823 13.8363 11.3657 13.3243 11.3657 12.6909C11.3657 12.0574 11.872 11.5454 12.5054 11.5454H12.5158C13.1492 11.5454 13.6612 12.0574 13.6612 12.6909C13.6612 13.3243 13.1492 13.8363 12.5158 13.8363Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.92389 13.8363C7.29045 13.8363 6.77271 13.3243 6.77271 12.6909C6.77271 12.0574 7.28014 11.5454 7.91358 11.5454H7.92389C8.55732 11.5454 9.06934 12.0574 9.06934 12.6909C9.06934 13.3243 8.55732 13.8363 7.92389 13.8363Z"
        fill="currentColor"
      />
      <rect
        x="2"
        y="2"
        width="21"
        height="21"
        rx="6"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </>
  ),
  Previous: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23.75C18.2132 23.75 23.25 18.7132 23.25 12.5C23.25 6.2868 18.2132 1.25 12 1.25C5.7868 1.25 0.75 6.2868 0.75 12.5C0.75 18.7132 5.7868 23.75 12 23.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 7.25L7.74398 11.867C7.52788 12.005 7.39709 12.2436 7.39709 12.5C7.39709 12.7564 7.52788 12.995 7.74398 13.133L15 17.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Next: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23.75C18.2132 23.75 23.25 18.7132 23.25 12.5C23.25 6.2868 18.2132 1.25 12 1.25C5.7868 1.25 0.75 6.2868 0.75 12.5C0.75 18.7132 5.7868 23.75 12 23.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 17.75L16.256 13.133C16.4721 12.995 16.6029 12.7564 16.6029 12.5C16.6029 12.2436 16.4721 12.005 16.256 11.867L9 7.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Menu: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 2.75299C1.25 1.92456 1.92157 1.25299 2.75 1.25299H22.25C23.0784 1.25299 23.75 1.92456 23.75 2.75299V22.253C23.75 23.0814 23.0784 23.753 22.25 23.753H2.75C1.92157 23.753 1.25 23.0814 1.25 22.253V2.75299Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 18.253L7.5 7.75299"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18.253V7.75299"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 18.253V7.75299"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  AllNotes: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.629 23.75H2.25C1.42157 23.75 0.75 23.0784 0.75 22.25V2.75C0.75 1.92157 1.42157 1.25 2.25 1.25H21.75C22.5784 1.25 23.25 1.92157 23.25 2.75V14.129C23.2499 14.5265 23.092 14.9078 22.811 15.189L14.689 23.311C14.4078 23.592 14.0265 23.7499 13.629 23.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 23.615V16.25C14.25 15.4216 14.9216 14.75 15.75 14.75H23.115"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 7.25H18.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 11.75H12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Notes: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.63 15.362L15 22.992L11.25 23.742L12 19.992L19.63 12.362C20.4563 11.536 21.7957 11.536 22.622 12.362L22.63 12.371C23.0271 12.7674 23.2503 13.3054 23.2503 13.8665C23.2503 14.4276 23.0271 14.9656 22.63 15.362V15.362Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 20.742H2.25C1.8492 20.752 1.46184 20.5972 1.17834 20.3137C0.894842 20.0302 0.740014 19.6428 0.749998 19.242V4.349C0.72189 3.49157 1.39266 2.77295 2.25 2.742H17.25C18.1073 2.77295 18.7781 3.49157 18.75 4.349V8.742"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 1.242V5.742"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 1.242V5.742"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 1.242V5.742"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Print: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81451 2.74902C6.81451 2.33481 7.1503 1.99902 7.56451 1.99902H18.0645C18.4787 1.99902 18.8145 2.33481 18.8145 2.74902V6.49902H6.81451V2.74902ZM5.31451 6.49902V2.74902C5.31451 1.50638 6.32187 0.499023 7.56451 0.499023H18.0645C19.3072 0.499023 20.3145 1.50638 20.3145 2.74902V6.49902H22.5645C23.8072 6.49902 24.8145 7.50638 24.8145 8.74902V16.249C24.8145 17.4917 23.8072 18.499 22.5645 18.499H20.3145V23.749C20.3145 24.1632 19.9787 24.499 19.5645 24.499H6.06451C5.6503 24.499 5.31451 24.1632 5.31451 23.749V18.499H3.06451C1.82187 18.499 0.814514 17.4917 0.814514 16.249V8.74902C0.814514 7.50638 1.82187 6.49902 3.06451 6.49902H5.31451ZM6.06451 7.99902H3.06451C2.6503 7.99902 2.31451 8.33481 2.31451 8.74902V16.249C2.31451 16.6632 2.6503 16.999 3.06451 16.999H5.31451V13.249C5.31451 12.8348 5.6503 12.499 6.06451 12.499H19.5645C19.9787 12.499 20.3145 12.8348 20.3145 13.249V16.999H22.5645C22.9787 16.999 23.3145 16.6632 23.3145 16.249V8.74902C23.3145 8.33481 22.9787 7.99902 22.5645 7.99902H19.5645H6.06451ZM18.8145 13.999V17.749V22.999H6.81451V17.749V13.999H18.8145ZM4.56451 9.49902C4.1503 9.49902 3.81451 9.83481 3.81451 10.249C3.81451 10.6632 4.1503 10.999 4.56451 10.999H6.06451C6.47873 10.999 6.81451 10.6632 6.81451 10.249C6.81451 9.83481 6.47873 9.49902 6.06451 9.49902H4.56451ZM8.31451 16.249C8.31451 15.8348 8.6503 15.499 9.06451 15.499H16.5645C16.9787 15.499 17.3145 15.8348 17.3145 16.249C17.3145 16.6632 16.9787 16.999 16.5645 16.999H9.06451C8.6503 16.999 8.31451 16.6632 8.31451 16.249ZM9.06451 18.499C8.6503 18.499 8.31451 18.8348 8.31451 19.249C8.31451 19.6632 8.6503 19.999 9.06451 19.999H14.3145C14.7287 19.999 15.0645 19.6632 15.0645 19.249C15.0645 18.8348 14.7287 18.499 14.3145 18.499H9.06451Z"
        fill="currentColor"
        fillOpacity="0.87"
      />
    </>
  ),
  Refresh: (
    <>
      <path
        d="M5.68951 14.748V19.248H1.18951"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1895 10.248V5.74805H23.6895"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4715 5.745C22.6698 9.0771 23.0921 14.1959 20.4829 18.007C17.8738 21.8182 12.9489 23.2763 8.68549 21.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.40651 19.251C2.21527 15.9174 1.79729 10.8034 4.40488 6.99585C7.01247 3.18829 11.9318 1.7295 16.1935 3.50002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Search: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0403 19.3028C19.1351 17.5625 21.0438 12.8323 19.3036 8.73759C17.5633 4.64283 12.8332 2.73411 8.7384 4.47434C4.64364 6.21457 2.73492 10.9448 4.47515 15.0395C6.21538 19.1343 10.9456 21.043 15.0403 19.3028Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5853 17.5844L23.8333 23.8333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Sort: (
    <>
      <path
        d="M9.5 11V23.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 20.75L9.5 23.75L6.5 20.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 14V1.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 4.25L15.5 1.25L18.5 4.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Reassign: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.13 15.369L15.5 23L11.75 23.75L12.5 20L20.13 12.369C20.9563 11.543 22.2957 11.543 23.122 12.369L23.13 12.378C23.5271 12.7744 23.7503 13.3125 23.7503 13.8735C23.7503 14.4346 23.5271 14.9727 23.13 15.369V15.369Z"
        stroke=" currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9.5C10.2782 9.5 12.125 7.65317 12.125 5.375C12.125 3.09683 10.2782 1.25 8 1.25C5.72183 1.25 3.875 3.09683 3.875 5.375C3.875 7.65317 5.72183 9.5 8 9.5Z"
        stroke=" currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.838 14.4189C12.3324 11.7533 9.2172 10.4399 6.2576 11.2231C3.29801 12.0062 1.24011 14.6885 1.25 17.7499"
        stroke=" currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Share: (
    <>
      <path
        d="M16.25 16.25V22.25C16.25 23.0784 15.5784 23.75 14.75 23.75H2.75C1.92157 23.75 1.25 23.0784 1.25 22.25V10.25C1.25 9.42157 1.92157 8.75 2.75 8.75H3.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 13.25V10.25H11.75C10.9216 10.25 10.25 10.9216 10.25 11.75V14.75C10.25 14.75 7.25 13.25 7.25 10.25V7.25C7.25 5.59315 8.59315 4.25 10.25 4.25H16.25V1.25L23.75 7.25L16.25 13.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Check: (
    <path
      d="M3 6V18H13.32C13.1075 17.3546 12.9995 16.6795 13 16H7C7 15.4696 6.78929 14.9609 6.41421 14.5858C6.03914 14.2107 5.53043 14 5 14V10C6.11 10 7 9.11 7 8H17C17 8.53043 17.2107 9.03914 17.5858 9.41421C17.9609 9.78929 18.4696 10 19 10V10.06C19.67 10.06 20.34 10.18 21 10.4V6H3ZM12 9C10.3 9.03 9 10.3 9 12C9 13.7 10.3 14.94 12 15C12.38 15 12.77 14.92 13.14 14.77C13.41 13.67 13.86 12.63 14.97 11.61C14.85 10.28 13.59 8.97 12 9ZM21.63 12.27L17.76 16.17L16.41 14.8L15 16.22L17.75 19L23.03 13.68L21.63 12.27Z"
      fill="currentColor"
    />
  ),
  List: (
    <>
      <path
        d="M8.75 4.24805H23.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 13.248H23.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 22.248H23.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 1.24805L5.75 5.74805"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 5.74805L5.75 1.24805"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 10.248L5.75 14.748"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 14.748L5.75 10.248"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 19.248L5.75 23.748"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 23.748L5.75 19.248"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Send: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.89999 3.64296C1.57953 3.06956 1.66944 2.35435 2.12184 1.8781C2.57425 1.40185 3.28389 1.27536 3.87299 1.56596L22.887 11.225C23.1385 11.3529 23.297 11.6112 23.297 11.8935C23.297 12.1757 23.1385 12.434 22.887 12.562L3.87299 22.234C3.28389 22.5246 2.57425 22.3981 2.12184 21.9218C1.66944 21.4456 1.57953 20.7304 1.89999 20.157L6.95799 11.893L1.89999 3.64296Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.297 11.8931H6.953"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  EditInteraction: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.13 15.3799L15.5 23.0099L11.75 23.7599L12.5 20.0099L20.131 12.3799C20.957 11.554 22.296 11.554 23.122 12.3799L23.131 12.3889C23.9566 13.2152 23.9562 14.5543 23.13 15.3799Z"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.74902 11.01H14.749"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.74902 14.76H11.749"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.74902 18.51H8.74902"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.74902 23.76H2.74902C1.9206 23.76 1.24902 23.0884 1.24902 22.26V6.51001C1.24902 5.68158 1.9206 5.01001 2.74902 5.01001H6.50002C6.50002 2.93894 8.17896 1.26001 10.25 1.26001C12.3211 1.26001 14 2.93894 14 5.01001H17.75C18.5785 5.01001 19.25 5.68158 19.25 6.51001V8.76001"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.249 4.26001C10.4561 4.26001 10.624 4.4279 10.624 4.63501C10.624 4.84212 10.4561 5.01001 10.249 5.01001C10.0419 5.01001 9.87402 4.84212 9.87402 4.63501C9.87376 4.53547 9.91318 4.43994 9.98357 4.36955C10.0539 4.29917 10.1495 4.25974 10.249 4.26001"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  EditTask: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.13 15.3799L15.5 23.0099L11.75 23.7599L12.5 20.0099L20.131 12.3799C20.957 11.554 22.296 11.554 23.122 12.3799L23.131 12.3889C23.9566 13.2152 23.9562 14.5543 23.13 15.3799Z"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.74902 11.01H14.749"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.74902 14.76H11.749"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.74902 18.51H8.74902"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.74902 23.76H2.74902C1.9206 23.76 1.24902 23.0884 1.24902 22.26V6.51001C1.24902 5.68158 1.9206 5.01001 2.74902 5.01001H6.50002C6.50002 2.93894 8.17896 1.26001 10.25 1.26001C12.3211 1.26001 14 2.93894 14 5.01001H17.75C18.5785 5.01001 19.25 5.68158 19.25 6.51001V8.76001"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.249 4.26001C10.4561 4.26001 10.624 4.4279 10.624 4.63501C10.624 4.84212 10.4561 5.01001 10.249 5.01001C10.0419 5.01001 9.87402 4.84212 9.87402 4.63501C9.87376 4.53547 9.91318 4.43994 9.98357 4.36955C10.0539 4.29917 10.1495 4.25974 10.249 4.26001"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  ExcelFileType: (
    <>
      <path
        d="M5.74902 23.748H2.74902C1.9206 23.748 1.24902 23.0765 1.24902 22.248V2.74805C1.24902 1.91962 1.9206 1.24805 2.74902 1.24805H13.378C13.7756 1.24813 14.1568 1.40603 14.438 1.68705L20.31 7.55905C20.591 7.84025 20.7489 8.2215 20.749 8.61905V13.248"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.749 8.74805H14.749C13.9206 8.74805 13.249 8.07647 13.249 7.24805V1.24805"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.74902 16.248L11.749 23.748"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.749 16.248L8.74902 23.748"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.749 16.248V22.248C14.749 23.0765 15.4206 23.748 16.249 23.748H17.749"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.749 16.248H22.249C21.4206 16.248 20.749 16.9196 20.749 17.748C20.749 19.998 23.749 19.998 23.749 22.248C23.749 23.0765 23.0775 23.748 22.249 23.748H20.749"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Edit: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.69 2.31002C22.0021 1.62497 21.069 1.24327 20.0982 1.24984C19.1274 1.25641 18.1995 1.65072 17.521 2.34502L3.021 16.845L1.25 23.75L8.155 21.979L22.655 7.47902C23.3493 6.80049 23.7436 5.8726 23.7502 4.90182C23.7568 3.93104 23.3751 2.99789 22.69 2.31002Z"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.106 2.76001L22.24 7.89401"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0122 4.854L20.1462 9.988"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.021 16.845L8.16 21.974"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  EditHighlight: (
    <path
      d="M2 23.9999V21.9999H22V23.9999H2ZM6 16.8269H7.07308L15.8731 8.03264L15.3404 7.48072L14.7942 6.95379L6 15.7538V16.8269ZM5 17.8269V15.3269L16.2192 4.11339C16.3256 4.00698 16.442 3.92941 16.5683 3.88069C16.6946 3.83197 16.8232 3.80762 16.9542 3.80762C17.0852 3.80762 17.2122 3.83197 17.335 3.88069C17.4578 3.92941 17.5744 4.0089 17.6846 4.11917L18.7135 5.15379C18.8237 5.26019 18.9022 5.37534 18.949 5.49924C18.9958 5.62314 19.0192 5.75117 19.0192 5.88332C19.0192 6.00722 18.9949 6.13333 18.9461 6.26164C18.8974 6.38996 18.8198 6.50722 18.7135 6.61342L7.5 17.8269H5ZM15.8731 8.03264L15.3404 7.48072L14.7942 6.95379L15.8731 8.03264Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      fillRule="evenodd"
    />
  ),
  Visibility: (
    <>
      <path
        d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
        stroke="currentColor"
      />
    </>
  ),
  NoVisibility: (
    <>
      <path
        d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
        stroke="currentColor"
      />
    </>
  ),
  Organization: (
    <>
      <path
        d="M2.959 21.833V3.417H12.709V7.75H23.042V21.833H2.959ZM4.042 20.75H11.625V17.5H4.042V20.75ZM4.042 16.417H11.625V13.167H4.042V16.417ZM4.042 12.083H11.625V8.833H4.042V12.083ZM4.042 7.75H11.625V4.5H4.042V7.75ZM12.709 20.75H21.959V8.833H12.709V20.75ZM15.292 13.167V12.083H18.959V13.167H15.292ZM15.292 17.5V16.417H18.959V17.5H15.292Z"
        fill="currentColor"
      />
    </>
  ),
  AuthenticatorApp: (
    <>
      <path
        d="M12.999.52C8.82.52 6.04 3.554 5.751 7.17 5.676 7.2 5.595 7.225 5.522 7.256 3.705 8.023 2.599 9.445 2.599 11.441 2.599 14.687 3.819 18.146 5.708 20.835 7.597 23.523 10.185 25.481 12.999 25.481 15.813 25.481 18.402 23.523 20.291 20.835 22.18 18.146 23.399 14.686 23.399 11.441 23.399 9.445 22.294 8.023 20.477 7.256 20.404 7.225 20.323 7.2 20.248 7.17 19.959 3.554 17.177.52 12.999.52zM12.999 4.161C14.577 4.161 15.725 5.209 16.274 6.361 15.274 6.277 14.18 6.241 12.999 6.241 11.817 6.241 10.725 6.276 9.724 6.361 10.272 5.209 11.42 4.161 12.999 4.161zM12.997 8.326C15.002 8.326 16.63 9.952 16.63 11.957 16.63 13.962 15.002 15.589 12.997 15.589 10.992 15.589 9.364 13.962 9.364 11.957 9.364 9.952 10.992 8.326 12.997 8.326zM12.999 16.647C15.629 16.647 17.925 18.152 19.314 20.402 17.562 22.822 15.268 24.441 12.999 24.441 10.73 24.441 8.436 22.821 6.684 20.4 8.072 18.151 10.367 16.647 12.999 16.647z"
        fillRule="evenodd"
        fill="currentColor"
        //stroke="currentColor"
      />
    </>
  ),
  SkypeCircleCheck: (
    <>
      <path
        d="M13 0c1.193 0 2.344.154 3.453.463 1.109.309 2.143.747 3.104 1.314a13.171 13.171 0 0 1 2.628 2.038 13.171 13.171 0 0 1 2.038 2.628c.567.961 1.005 1.995 1.314 3.104C25.846 10.656 26 11.807 26 13c0 1.193-.154 2.344-.463 3.453-.309 1.109-.747 2.143-1.314 3.104a13.171 13.171 0 0 1-2.038 2.628 13.171 13.171 0 0 1-2.628 2.038c-.961.567-1.995 1.005-3.104 1.314C15.344 25.846 14.193 26 13 26c-1.193 0-2.344-.154-3.453-.463-1.109-.309-2.143-.747-3.104-1.314a13.171 13.171 0 0 1-2.628-2.038 13.171 13.171 0 0 1-2.038-2.628c-.567-.961-1.005-1.995-1.314-3.104C.154 15.344 0 14.193 0 13c0-1.193.154-2.344.463-3.453.309-1.109.747-2.143 1.314-3.104a13.171 13.171 0 0 1 2.038-2.628 13.171 13.171 0 0 1 2.628-2.038C7.404 1.21 8.438.772 9.547.463 10.656.154 11.807 0 13 0zm-1.485 17.101c.186 0 .364-.036.533-.108a1.391 1.391 0 0 0 .444-.298l5.446-5.446a1.309 1.309 0 0 0 .292-.444c.068-.169.102-.343.102-.521 0-.186-.036-.364-.108-.533-.072-.169-.169-.315-.292-.438-.123-.123-.269-.22-.438-.292a1.349 1.349 0 0 0-.533-.108c-.178 0-.353.034-.527.102a1.295 1.295 0 0 0-.451.292l-4.469 4.469-1.498-1.498c-.271-.271-.597-.406-.977-.406-.186 0-.364.036-.533.108a1.391 1.391 0 0 0-.444.298 1.412 1.412 0 0 0-.298.438 1.304 1.304 0 0 0-.108.527c0 .178.036 .353.108 .527.072 .173.171 .324.298 .451l2.476 2.476c.127.127 .277.226 .451.298 .173.072 .349.108 .527.108z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </>
  ),
  DragHandle: (
    <>
      <circle cx="1.5" cy="1.5" r="1.5" fill="currentColor" />
      <circle cx="1.5" cy="6.5" r="1.5" fill="currentColor" />
      <circle cx="6.5" cy="1.5" r="1.5" fill="currentColor" />
      <circle cx="6.5" cy="6.5" r="1.5" fill="currentColor" />
      <circle cx="11.5" cy="1.5" r="1.5" fill="currentColor" />
      <circle cx="11.5" cy="6.5" r="1.5" fill="currentColor" />
      <circle cx="16.5" cy="1.5" r="1.5" fill="currentColor" />
      <circle cx="16.5" cy="6.5" r="1.5" fill="currentColor" />
    </>
  ),
  NoData: (
    <>
      <path
        d="M20.583 17.794 19.499 16.71V5C19.499 4.833 19.43 4.68 19.291 4.542 19.152 4.403 18.999 4.333 18.833 4.333H11.754L9.42 6.667 8.641 5.9 11.291 3.25H18.833C19.331 3.25 19.748 3.417 20.082 3.751 20.416 4.085 20.583 4.501 20.583 5V17.794ZM22.124 23.95 7.125 8.95 6.499 9.575V21C6.499 21.167 6.569 21.32 6.708 21.458 6.847 21.597 6.999 21.667 7.166 21.667H18.833C18.999 21.667 19.152 21.597 19.291 21.458 19.43 21.32 19.499 21.167 19.499 21V19.785L20.583 20.869V21C20.583 21.499 20.416 21.915 20.082 22.249 19.748 22.583 19.331 22.75 18.833 22.75H7.166C6.668 22.75 6.251 22.583 5.917 22.249 5.583 21.915 5.416 21.499 5.416 21V9.125L6.358 8.183 2.216 4.042 2.989 3.275 22.891 23.177 22.124 23.95Z"
        fill="currentColor"
      />
    </>
  ),
  Calendar: (
    <>
      <path
        clipRule="evenodd"
        d="M1.25195 5.75C1.25195 4.92157 1.92353 4.25 2.75195 4.25H22.252C23.0804 4.25 23.752 4.92157 23.752 5.75V22.25C23.752 23.0784 23.0804 23.75 22.252 23.75H2.75195C1.92353 23.75 1.25195 23.0784 1.25195 22.25V5.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25195 10.25H23.752"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25195 6.5V1.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.752 6.5V1.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  Exclamation: (
    <>
      <path
        d="M7.49987 10.6016C7.38021 10.6016 7.2832 10.6986 7.2832 10.8182C7.2832 10.9379 7.38021 11.0349 7.49987 11.0349C7.61953 11.0349 7.71654 10.9379 7.71654 10.8182C7.71654 10.6986 7.61953 10.6016 7.49987 10.6016V10.6016"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 8.86823V4.10156"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 14.5C11.0899 14.5 14 11.5899 14 8C14 4.41015 11.0899 1.5 7.5 1.5C3.91015 1.5 1 4.41015 1 8C1 11.5899 3.91015 14.5 7.5 14.5Z"
        stroke="currentColor"
        strokeWidth="1.3"
      />
    </>
  )
}
