import { Stack } from '@fluentui/react'
import { NewMessageButton } from './NewMessageButton'

export interface ISecureMessagesProps {
  left?: React.ReactNode
  right?: React.ReactNode
}

export const SecureMessagesHeader: React.FC<ISecureMessagesProps> = ({
  left,
  right
}) => {
  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="end"
      wrap={true}
    >
      <Stack.Item>
        <Stack horizontal tokens={{ childrenGap: 40 }} verticalAlign="center">
          <NewMessageButton />
          {left}
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="end">
          {right}
        </Stack>
      </Stack.Item>
    </Stack>
  )
}
