import { css } from '@emotion/react'
import { ReactComponent as Logo } from 'modules/Advisory/shared/images/bannerRockefellerGlobalFamilyOffice.svg'
import { useMemo } from 'react'

const getClasses = () => ({
  smallLogoContainer: css({
    padding: '0 0 10px 0!important',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: -1,
    borderBottom: '1px solid #CDCDCD'
  }),
  largeLogoContainer: css({
    backgroundColor: '#FFF',
    width: '100%',
    display: 'flex'
  }),
  smallerLogo: css({
    width: 252,
    height: 37
  }),
  headerColumns: css({
    display: 'flex',
    justifyContent: 'space-between',
    h1: css({
      fontSize: 26,
      margin: 0
    })
  }),
  headerLeft: css({
    textAlign: 'left',
    width: '70%'
  }),
  headerRight: css({
    textAlign: 'right',
    width: '30%'
  })
})
interface IPrintHeaderGlobalProps {
  name: string
}

export const PrintHeaderGlobal: React.FC<IPrintHeaderGlobalProps> = ({
  name
}) => {
  const classes = useMemo(() => getClasses(), [])
  return (
    <>
      {
        // Displays large header on first page only
      }
      <div css={[classes.headerColumns, classes.largeLogoContainer]}>
        <div css={classes.headerLeft}>
          <h1>{name}</h1>
        </div>
        <div css={classes.headerRight}>
          <Logo />
        </div>
      </div>
      {
        // Renders small header on all page using position fixed
        // but is hidden under Large header on page 1 using z-index -1.
        // Large header has background white to hide small header on page 1.
      }
      <div className="small-logo-header">
        <div css={[classes.headerColumns, classes.smallLogoContainer]}>
          <div css={classes.headerLeft}>
            <h1>{name}</h1>
          </div>
          <div css={classes.headerRight}>
            <Logo css={classes.smallerLogo} />
          </div>
        </div>
      </div>
    </>
  )
}
