import { TooltipHost } from '@fluentui/react'
import { createColumnHelper } from '@tanstack/react-table'
import { IBalanceDetailResponseValueItem } from 'modules/Advisory/modules/Rdot360/store/balancesApi/IBalanceDetailResponse'
import { theme } from 'shared/theme'
import { Icon } from 'shared/uiSharedComponents/Icon'
import { textAlignLeft } from '../../../features/Loans/styles'
import {
  ColumnHeaderWithSortIndicator,
  DisplayCell,
  PercentageCell,
  USDCell,
  AccountCellExtended,
  MarginTypeCell,
  USDCellWithDetails,
  USDCellWithModal
} from '../../../features/Loans/TableCellsGeneric'

const TotalAccountDisclaimer =
  'This value includes annuities and insurance, other income, and accrued income, which are not displayed within the Margin Report. Please refer to the Balances for further details.'

const columnHelper = createColumnHelper<IBalanceDetailResponseValueItem>()

export const getColumnDefinitions = () => [
  columnHelper.display({
    id: 'display',
    cell: DisplayCell,
    aggregatedCell: DisplayCell,
    size: 20,
    meta: { className: 'displayCell' }
  }),
  columnHelper.accessor(({ key }) => key, {
    id: 'accountKey',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props} leftAligned>
        Account
      </ColumnHeaderWithSortIndicator>
    ),
    cell: AccountCellExtended,
    aggregatedCell: AccountCellExtended
  }),
  columnHelper.accessor(
    ({ ismultimargin, isprimary }) => [ismultimargin, isprimary],
    {
      id: 'marginType',
      header: (props) => (
        <ColumnHeaderWithSortIndicator {...props} leftAligned>
          Margin Type
        </ColumnHeaderWithSortIndicator>
      ),
      cell: MarginTypeCell,
      aggregatedCell: MarginTypeCell
    }
  ),
  columnHelper.accessor(({ customername }) => customername, {
    id: 'customerName',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props} leftAligned>
        Borrower&apos;s Name
      </ColumnHeaderWithSortIndicator>
    ),
    cell: ({ getValue }) => <div css={textAlignLeft}>{getValue() || '--'}</div>,
    aggregationFn: 'max',
    aggregatedCell: ({ getValue }) => (
      <div css={textAlignLeft}>{getValue() || '--'}</div>
    )
  }),
  columnHelper.accessor(({ pricedinvestments }) => pricedinvestments, {
    id: 'investments',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Investments
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell
  }),
  columnHelper.accessor(({ cashmoneyaccounts }) => cashmoneyaccounts, {
    id: 'cash',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Cash
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCellWithModal,
    aggregatedCell: USDCellWithModal
  }),
  columnHelper.accessor(({ marginbalance }) => marginbalance, {
    id: 'marginbalance',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Loan Balance
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell
  }),
  columnHelper.accessor(({ totalaccountvalue }) => totalaccountvalue, {
    id: 'totalAccountvalue',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        <div
          css={{
            display: 'inline-flex',
            marginRight: 2
          }}
        >
          <TooltipHost
            content={TotalAccountDisclaimer}
            tooltipProps={{ maxWidth: '400px' }}
            closeDelay={800}
          >
            <Icon
              type="Info"
              width={12}
              height={12}
              color={theme.colors.tertiaryBlue1}
            />
          </TooltipHost>
        </div>
        <span>Total Account Value</span>
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell
  }),
  columnHelper.accessor(() => null, {
    id: 'percentToHouseCall',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        % to house Call
      </ColumnHeaderWithSortIndicator>
    ),
    cell: PercentageCell,
    aggregatedCell: PercentageCell
  }),
  columnHelper.accessor(({ availablewithdraw }) => availablewithdraw, {
    id: 'availableToWithdrawCash',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Cash
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCellWithDetails,
    aggregatedCell: USDCellWithDetails
  }),
  columnHelper.accessor(
    ({ cashmarginavailabletowithdraw = 0, availablewithdraw = 0 }) =>
      cashmarginavailabletowithdraw - availablewithdraw,
    {
      id: 'availableToWithdrawMargin',
      header: (props) => (
        <ColumnHeaderWithSortIndicator {...props}>
          Margin
        </ColumnHeaderWithSortIndicator>
      ),
      cell: USDCellWithDetails,
      aggregatedCell: USDCellWithDetails
    }
  ),
  columnHelper.accessor(
    ({ cashmarginavailabletowithdraw }) => cashmarginavailabletowithdraw,
    {
      id: 'availableToWithdrawTotal',
      header: (props) => (
        <ColumnHeaderWithSortIndicator {...props}>
          Total
        </ColumnHeaderWithSortIndicator>
      ),
      cell: USDCell,
      aggregatedCell: USDCell
    }
  ),
  columnHelper.accessor(({ cashbuyingpower }) => cashbuyingpower, {
    id: 'availableToInvestCash',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Cash
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell
  }),
  columnHelper.accessor(
    ({ nonmarginbuyingpower = 0, cashbuyingpower = 0 }) =>
      nonmarginbuyingpower - cashbuyingpower,
    {
      id: 'availableToInvestMargin',
      header: (props) => (
        <ColumnHeaderWithSortIndicator {...props}>
          Margin
        </ColumnHeaderWithSortIndicator>
      ),
      cell: USDCell,
      aggregatedCell: USDCell
    }
  ),
  columnHelper.accessor(({ marginintrate }) => marginintrate, {
    id: 'marginInterestRate',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Interest Rate
      </ColumnHeaderWithSortIndicator>
    ),
    cell: PercentageCell,
    aggregatedCell: PercentageCell
  }),
  columnHelper.accessor(({ marginaccruedinterest }) => marginaccruedinterest, {
    id: 'marginAccruedInterest',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Pending Interest Charge
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell
  })
]
