import { css } from '@emotion/react'
import { theme } from 'shared/theme'

const { tertiaryGray4 } = theme.colors

export const getBorderStyles = (id: string, borderColor?: string) => {
  const isAvailableCredit = id === 'availableCredit'
  const isInterestRate = id === 'interestRate'
  const borderStyle =
    isAvailableCredit || isInterestRate
      ? `1px dashed ${borderColor ?? tertiaryGray4}`
      : undefined
  return css({
    borderLeft: borderStyle,
    borderRight: isAvailableCredit ? borderStyle : undefined
  })
}

export const getMLBorderStyles = (id: string, borderColor?: string) => {
  const colIds = [
    'percentToHouseCall',
    'availableToWithdrawCash',
    'availableToInvestCash',
    'marginInterestRate',
    'colSpanHeader'
  ]
  const borderStyle = colIds.includes(id)
    ? `1px dashed ${borderColor ?? tertiaryGray4}`
    : undefined
  return css({
    borderLeft: borderStyle
  })
}
