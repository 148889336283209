import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  GroupingState,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import { useDebounce } from 'shared/hooks/useDebounce'
import { IBalanceDetailResponseValueItem } from '../../../store/balancesApi/IBalanceDetailResponse'
import { useRdot360BalancesContext } from '../../../store/rdot360Context/useRdot360BalancesContext'
import { getColumnDefinitions } from './ColumnDefinitions'
import { globalFilterFn } from './globalFilterFn'
import { useThirdPartyLoansUiState } from './store'

const columns = getColumnDefinitions()

export const useReactTableProps = () => {
  const {
    searchText,
    setSearchText,
    expanded,
    setExpanded,
    sorting,
    setSorting
  } = useThirdPartyLoansUiState()
  const [grouping, setGrouping] = useState<GroupingState>(['accountKey'])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const debouncedSearchText = useDebounce(searchText, 100)
  const {
    balancesResponse,
    isFetching,
    isUninitialized,
    invalidateCache,
    error
  } = useRdot360BalancesContext()
  const tableData: IBalanceDetailResponseValueItem[] = useMemo(() => {
    const { accountbalances = [] } = balancesResponse || {}
    return accountbalances
  }, [balancesResponse])

  const flatTableData = useMemo(() => {
    return tableData
      .flatMap((item) => {
        if (item.notes?.length) {
          const { notes } = item
          return [...notes.map((subItem) => ({ ...subItem }))]
        }
        if (
          item['@odata.type'] &&
          item['@odata.type'].indexOf('LoanNote') !== -1
        ) {
          return [item]
        }
        return [null]
      })
      .filter(isNotNullOrUndefined)
  }, [tableData])

  const tableOptions = useMemo(
    () => ({
      data: flatTableData,
      columns,
      state: {
        sorting,
        grouping,
        expanded,
        globalFilter: debouncedSearchText,
        columnVisibility
      },
      autoResetExpanded: false,
      groupedColumnMode: undefined,
      getExpandedRowModel: getExpandedRowModel(),
      getGroupedRowModel: getGroupedRowModel(),
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onExpandedChange: setExpanded,
      onGlobalFilterChange: setSearchText,
      onSortingChange: setSorting,
      onGroupingChange: setGrouping,
      onColumnVisibilityChange: setColumnVisibility,
      globalFilterFn
    }),
    [
      columnVisibility,
      debouncedSearchText,
      expanded,
      flatTableData,
      grouping,
      setExpanded,
      setSearchText,
      setSorting,
      sorting
    ]
  )

  const table = useReactTable(tableOptions)
  const { rows } = table.getRowModel()
  const canSomeRowsExpand = rows.some((row) => row.getCanExpand())
  const headers = table.getFlatHeaders()
  const { getToggleAllRowsExpandedHandler } = table
  const isAllRowsExpanded = table.getIsAllRowsExpanded()
  const showNoData = useMemo(
    () => !isUninitialized && !isFetching && !error && !rows.length,
    [error, isFetching, isUninitialized, rows.length]
  )

  return {
    canSomeRowsExpand,
    getToggleAllRowsExpandedHandler,
    headers,
    invalidateCache,
    isAllRowsExpanded,
    isFetching,
    rows,
    searchText,
    setSearchText,
    sorting,
    setSorting,
    showNoData,
    error
  }
}
