import { css } from '@emotion/react'

export const PrintDisclaimer: React.FC = () => {
  const classes = {
    disclaimer: css({
      fontSize: 14
    })
  }
  return (
    <>
      <h2>Disclosures</h2>
      <section css={classes.disclaimer}>
        <p>
          Note: Aggregated data is provided for your convenience and may contain
          investments held at other institutions outside of Rockefeller
          Financial LLC. To aggregate those accounts, system limitations may
          require transaction-data adjustments, and some values might vary from
          your external account statements. However, your statements always are
          the true and official record of your positions and transactions.
        </p>
        <p>(*) Indicates a pending activity.</p>
      </section>
    </>
  )
}
