interface IPrintHeaderSpacer {
  space: number
}

export const PrintHeaderSpacer: React.FC<IPrintHeaderSpacer> = ({ space }) => (
  <div
    css={{
      marginTop: space
    }}
  />
)
