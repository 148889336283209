import {
  CommandButton,
  DefaultButton,
  DirectionalHint,
  Dropdown,
  IDropdown,
  IDropdownOption,
  IDropdownStyles,
  IRenderFunction,
  ISelectableDroppableTextProps,
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TextField
} from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { skipToken } from '@reduxjs/toolkit/query'
import { keyBy, uniqBy } from 'lodash'
import { IndeterminateProgressIndicator } from 'modules/Advisory/modules/Rdot360/components/shared'
import { buttonStyles } from 'modules/Advisory/modules/Rdot360/components/shared/Buttons'
import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { openPreview } from 'modules/Advisory/modules/Rdot360/hooks/useDownloadAttachment'
import {
  useGetCollaborationGroupsQuery,
  useGetGroupMembersQuery,
  useGetGroupOwnersQuery
} from 'modules/Advisory/modules/Rdot360/store/graph'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'react-use'
import { CKEditorComponent } from 'shared/ckEditor'
import { PaletteComponent } from 'shared/components/Palette'
import { isNotNullOrUndefined } from 'shared/guards'
import { AttachFileDialog, AttachIconButton } from '../../features/Attachments'
import { BackToFolder } from '../../features/Header/BackToFolder'
import { SecureMessagesHeader } from '../../features/Header/SecureMessagesHeader'
import { AttachModal } from '../../features/Modal/AttachModal'
import { SendModal } from '../../features/Modal/SendModal'
import { SecureMessagesTableWrapper } from '../../features/Table/SecureMessagesTableWrapper'
import { ErrorMessage } from '../../shared/components'
import { ckEditorConfig } from '../../shared/constants'
import { getBase64, getFileSize } from '../../shared/functions'
import {
  useSecureMessagesMailbox,
  useSecureMessagesSession
} from '../../shared/hooks'
import { useCreateClientMessageMutation } from '../../shared/store'
import { ISecureMessageRequest } from '../../shared/types'

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdownItemsWrapper: { maxHeight: 384, overflowY: 'auto' },
  dropdownItem: { height: 48 },
  dropdownItemSelected: { height: 48 }
}

const errorMessageTo = 'Client is required'
const errorMessageSubject = 'Subject is required'

const serviceDeskUrl = 'https://servicedesk.rockco.com'

const onRenderDropdownOption = (option?: IDropdownOption): JSX.Element => {
  const { data, text } = option || {}

  return (
    <>
      <div style={{ marginRight: '6px' }}>
        <Icon
          type={data?.icon ?? 'Profile'}
          width={16}
          height={16}
          color="#005CB8"
        />
      </div>
      <div>
        <div>{(text && text.trim()) || '--'}</div>
        {data?.email != null && <div css={{ color: 'gray' }}>{data.email}</div>}
      </div>
    </>
  )
}

export const NewMessageContainer: React.FC = () => {
  const { messages, updateMessages } = useSecureMessagesSession()
  const { businessUnit, filteredParties, isMailboxesFetching, userObjectId } =
    useSecureMessagesMailbox()
  const [
    createClientMessage,
    {
      error: createError,
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      reset: resetCreate
    }
  ] = useCreateClientMessageMutation()

  const ckEditorRef = useRef<any>()
  const dropdownCcRef = useRef<IDropdown>(null)
  const dropdownToRef = useRef<IDropdown>(null)

  useEffect(() => {
    if (isCreateSuccess) {
      if (ckEditorRef.current) {
        ckEditorRef.current.setData('')
      }
      setAttachments([])
      setSelectedCc([])
      setSubject('')

      setTimeout(() => {
        resetCreate()
      }, 5000)
    }
  }, [isCreateSuccess, resetCreate])

  const [
    isAttachModalOpen,
    { setTrue: showAttachModal, setFalse: hideAttachModal }
  ] = useBoolean(false)
  const [isSendModalOpen, { setTrue: showSendModal, setFalse: hideSendModal }] =
    useBoolean(false)

  const [attachments, setAttachments] = useState<File[]>([])
  const [body, setBody] = useState<string>()
  const [selectedCc, setSelectedCc] = useState<string[]>([])
  const [selectedTo, setSelectedTo] = useState<IDropdownOption>()
  const [subject, setSubject] = useState<string>()
  const [subjectError, setSubjectError] = useState<string>()
  const [toError, setToError] = useState<string>()

  const subjectTrimmed = useMemo(() => subject?.trim(), [subject])

  const toDropdownOptions = useMemo(
    () =>
      filteredParties?.map(
        (x) =>
          ({
            key: x.aadUserOId,
            data: { email: x.loginId },
            text: x.partyName || '--'
          } as IDropdownOption)
      ) ?? [],
    [filteredParties]
  )

  const selectedKey = useMemo(() => selectedTo?.key.toString(), [selectedTo])

  useEffect(() => {
    if (!selectedKey) {
      return
    }

    const sessionMessage = messages && messages[selectedKey]

    const initialCc = (sessionMessage?.cc || [])
      .map((x) => x.id)
      .filter((x) => x !== undefined)
    const initialBody = sessionMessage?.body || ''
    const initialSubject = sessionMessage?.subject || ''

    if (ckEditorRef.current) {
      ckEditorRef.current.setData(initialBody)
    }
    setAttachments([])
    setSelectedCc(initialCc)
    setSubject(initialSubject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey])

  const { currentData: groups, isFetching: isGroupsFetching } =
    useGetCollaborationGroupsQuery(selectedKey || skipToken)

  const firstGroupId = useMemo(() => groups?.[0]?.id, [groups])
  const { currentData: groupMembers, isFetching: isMembersFetching } =
    useGetGroupMembersQuery(firstGroupId || skipToken)
  const { currentData: groupOwners, isFetching: isOwnersFetching } =
    useGetGroupOwnersQuery(firstGroupId || skipToken)

  const members = useMemo(() => {
    if (groupMembers && groupOwners) {
      return uniqBy([...groupMembers, ...groupOwners], (x) => x.id)
    }

    return groupMembers || groupOwners
  }, [groupMembers, groupOwners])
  const membersLookup = useMemo(() => keyBy(members, (x) => x.id), [members])
  const isMemberOfGroup = useMemo(
    () => members?.some((x) => x.id === userObjectId),
    [members, userObjectId]
  )

  const ccDropdownOptions = useMemo(() => {
    const options =
      members
        ?.filter((x) => !!x.accountEnabled && x.userType === 'Member')
        .map((x) => ({
          key: x.id,
          data: { email: x.mail },
          text: x.displayName || '--'
        })) ?? []
    options.sort((a, b) => a.text.localeCompare(b.text))

    if (businessUnit?.name && businessUnit?.emailaddress) {
      const teamMailbox = {
        key: businessUnit.businessunitid,
        data: {
          email: businessUnit.emailaddress,
          icon: 'Email'
        },
        text: businessUnit.name
      }

      return [teamMailbox, ...options]
    }

    return options
  }, [businessUnit, members])

  const isLoading = useMemo(
    () =>
      isMailboxesFetching ||
      isGroupsFetching ||
      isMembersFetching ||
      isOwnersFetching ||
      isCreateLoading,
    [
      isCreateLoading,
      isGroupsFetching,
      isMailboxesFetching,
      isMembersFetching,
      isOwnersFetching
    ]
  )

  const canSaveDraft = useMemo(
    () => !!isMemberOfGroup && !!selectedTo && !isLoading,
    [isLoading, isMemberOfGroup, selectedTo]
  )
  const canSend = useMemo(
    () => canSaveDraft && !!subjectTrimmed,
    [canSaveDraft, subjectTrimmed]
  )

  const secureMessage = useMemo(() => {
    const message: ISecureMessageRequest = {
      body,
      subject: subjectTrimmed || '',
      to: selectedTo
        ? [
            {
              id: selectedTo.key as string,
              name: selectedTo.text
            }
          ]
        : [],
      cc: selectedCc
        .map((x) => {
          if (x === businessUnit?.businessunitid) {
            return {
              id: businessUnit.businessunitid,
              email: businessUnit.emailaddress,
              name: businessUnit.name
            }
          }

          const member = membersLookup[x]
          if (!member?.mail) {
            return
          }

          return {
            id: member.id,
            email: member.mail,
            name: member.displayName
          }
        })
        .filter(isNotNullOrUndefined)
    }

    return message
  }, [
    body,
    businessUnit,
    membersLookup,
    selectedCc,
    selectedTo,
    subjectTrimmed
  ])

  useDebounce(
    () => selectedKey && updateMessages({ [selectedKey]: secureMessage }),
    1000,
    [selectedKey, secureMessage]
  )

  const removeAttachment = useCallback(
    (index: number) => {
      const attachmentsCopy = [...attachments]
      attachmentsCopy.splice(index, 1)

      setAttachments(attachmentsCopy)
    },
    [attachments]
  )

  const sendMessage = useCallback(() => {
    hideAttachModal()
    hideSendModal()

    if (!secureMessage || !selectedKey) {
      return
    }

    createClientMessage({
      attachments,
      request: secureMessage,
      userObjectId: selectedKey
    })
  }, [
    attachments,
    createClientMessage,
    hideAttachModal,
    hideSendModal,
    secureMessage,
    selectedKey
  ])

  const getAttachmentMenuProps = useCallback(
    (attachment: File, index: number) => ({
      directionalHint: DirectionalHint.bottomRightEdge,
      items: [
        {
          key: 'preview',
          text: 'Preview',
          onClick: async () => {
            const base64Data = await getBase64(attachment)
            openPreview(base64Data, attachment.type)
          }
        },
        {
          key: 'remove',
          text: 'Remove',
          onClick: () => removeAttachment(index)
        }
      ],
      styles: {
        root: { minWidth: 100 }
      }
    }),
    [removeAttachment]
  )

  const handleSaveDraftClick = useCallback(() => {
    if (!secureMessage || !selectedKey) {
      return
    }

    createClientMessage({
      attachments,
      request: { ...secureMessage, isDraft: true },
      userObjectId: selectedKey
    })
  }, [attachments, createClientMessage, secureMessage, selectedKey])

  const handleSendClick = useCallback(() => {
    if (attachments.length) {
      showAttachModal()
    } else if (!body) {
      showSendModal()
    } else {
      sendMessage()
    }
  }, [attachments, body, sendMessage, showAttachModal, showSendModal])

  const onRenderDropdownList: IRenderFunction<
    ISelectableDroppableTextProps<IDropdown, HTMLDivElement>
  > = useCallback(
    (props, defaultRender) => {
      if (!props) {
        return null
      }

      return (
        <>
          <div
            css={{
              padding: '8px',
              width: '100%',
              display: 'flex',
              gridColumnGap: 8
            }}
          >
            <div
              css={(theme) => ({
                color: theme.colors.tertiaryBlue1,
                cursor: 'pointer'
              })}
              onClick={() => setSelectedCc(ccDropdownOptions.map((x) => x.key))}
            >
              Select All
            </div>
            {' | '}
            <div
              css={(theme) => ({
                color: theme.colors.tertiaryBlue1,
                cursor: 'pointer'
              })}
              onClick={() => setSelectedCc([])}
            >
              Deselect All
            </div>
          </div>
          {defaultRender!(props)}
        </>
      )
    },
    [ccDropdownOptions]
  )

  const RightHeader = useCallback(() => {
    return (
      <>
        <AttachIconButton />
        <DefaultButton
          css={buttonStyles.secondary}
          disabled={!canSaveDraft}
          onClick={handleSaveDraftClick}
          text="Save as Draft"
          title="Save as draft message"
        />
        <PrimaryButton
          css={buttonStyles.primary}
          disabled={!canSend}
          onClick={handleSendClick}
          text="Send"
          title="Send message"
        />
      </>
    )
  }, [canSaveDraft, canSend, handleSaveDraftClick, handleSendClick])

  const error = createError as Error | undefined

  return (
    <>
      <SecureMessagesHeader left={<BackToFolder />} right={<RightHeader />} />
      <SecureMessagesTableWrapper>
        {isCreateSuccess && (
          <div style={{ marginBottom: '5px' }}>
            <SnackBar
              type="Success"
              message="Message has been submitted successfully."
            />
          </div>
        )}
        {!isLoading && !isMemberOfGroup && !!selectedKey && (
          <div style={{ marginBottom: '5px' }}>
            <MessageBar messageBarType={MessageBarType.warning}>
              You do not have access to send a message because you are not a
              member of this client&apos;s Collaboration team. Please reach out
              to the Service Desk at 325-RCM-HELP or
              <Link
                href={serviceDeskUrl}
                target="_blank"
                style={{ paddingRight: '4px' }}
              >
                {serviceDeskUrl}
              </Link>
              for additional assistance.
            </MessageBar>
          </div>
        )}
        <ErrorMessage error={error} />
        {isLoading && <IndeterminateProgressIndicator />}
        <PaletteComponent>
          <div
            css={(theme) => ({
              fontSize: theme.size.lg,
              fontWeight: theme.fontWeights.demi,
              marginBottom: '20px'
            })}
          >
            New Message
          </div>
          <form noValidate autoComplete="off">
            <Stack tokens={{ childrenGap: 20 }}>
              <Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  <Stack.Item basis={'35%'} grow={0}>
                    <Dropdown
                      label="To"
                      componentRef={dropdownToRef}
                      disabled={!toDropdownOptions.length}
                      errorMessage={toError}
                      options={toDropdownOptions}
                      placeholder="Select Client"
                      selectedKey={selectedTo?.key ?? null}
                      styles={dropdownStyles}
                      onBlur={() =>
                        setToError(selectedTo ? undefined : errorMessageTo)
                      }
                      onChange={(_, option) => setSelectedTo(option)}
                      onRenderOption={onRenderDropdownOption}
                      required
                    />
                  </Stack.Item>
                  <Stack.Item basis={'65%'} grow={0}>
                    <Dropdown
                      label="Cc"
                      componentRef={dropdownCcRef}
                      disabled={!ccDropdownOptions.length}
                      options={ccDropdownOptions}
                      selectedKeys={selectedCc}
                      styles={dropdownStyles}
                      onChange={(_, option) => {
                        if (option) {
                          setSelectedCc(
                            option.selected
                              ? [...selectedCc, option.key as string]
                              : selectedCc?.filter((x) => x !== option.key)
                          )
                        }
                      }}
                      onRenderList={onRenderDropdownList}
                      onRenderOption={onRenderDropdownOption}
                      multiSelect
                    />
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item>
                <TextField
                  label="Subject"
                  errorMessage={subjectError}
                  onBlur={(e) =>
                    setSubjectError(
                      e.target.value?.trim() ? undefined : errorMessageSubject
                    )
                  }
                  onChange={(_, newValue) => setSubject(newValue)}
                  value={subject}
                  required
                />
              </Stack.Item>
              <Stack.Item>
                {attachments.length > 0 && (
                  <div
                    css={(theme) => ({
                      border: `${theme.colors.tertiaryGray4} 1px solid`,
                      borderBottom: '0',
                      display: 'flex',
                      gap: '15px',
                      padding: '15px',
                      flexWrap: 'wrap'
                    })}
                  >
                    <div>{attachments.length} attachment(s):</div>
                    {attachments.map((x, i) => (
                      <CommandButton
                        key={i}
                        iconProps={{ iconName: 'Attach' }}
                        menuProps={getAttachmentMenuProps(x, i)}
                        styles={{ root: { height: 'auto' } }}
                        text={`${x.name} (${getFileSize(x.size)})`}
                      />
                    ))}
                  </div>
                )}
                <CKEditorComponent
                  initData={body}
                  config={ckEditorConfig}
                  onChange={(payload: any) => setBody(payload.editor.getData())}
                  onInstanceReady={(eventInfo: any) => {
                    const editor = eventInfo.editor
                    editor?.on('focus', () => {
                      dropdownCcRef.current?.dismissMenu()
                      dropdownToRef.current?.dismissMenu()
                    })
                    ckEditorRef.current = editor
                  }}
                />
              </Stack.Item>
            </Stack>
          </form>
        </PaletteComponent>
      </SecureMessagesTableWrapper>
      <AttachFileDialog
        onChange={(x) => setAttachments([...attachments, ...x])}
      />
      <AttachModal
        isModalOpen={isAttachModalOpen}
        onCancel={hideAttachModal}
        onSend={sendMessage}
      />
      <SendModal
        isModalOpen={isSendModalOpen}
        onCancel={hideSendModal}
        onSend={sendMessage}
      />
    </>
  )
}
