import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IPlainCardProps
} from '@fluentui/react'
import { FC, useMemo } from 'react'
import { Link } from '../../../components/shared/Link'
import { IBalanceDetailResponseValueItem } from '../../../store/balancesApi/IBalanceDetailResponse'
import { USD } from '../formatting'
import { CashDetails } from './CashDetails'
import { MarginDetails } from './MarginDetails'

export const HoverBox: FC<{
  columnId?: string
  originalData?: IBalanceDetailResponseValueItem
  value?: number
}> = ({ columnId, originalData, value }) => {
  const plainCardProps: IPlainCardProps = useMemo(
    () => ({
      onRenderPlainCard: () => {
        if (columnId?.includes('margin')) {
          return <MarginDetails originalData={originalData} />
        }
        if (columnId?.includes('cash')) {
          return <CashDetails originalData={originalData} />
        }
        return null
      },
      directionalHint: DirectionalHint.rightCenter,
      calloutProps: {
        isBeakVisible: true
      },
      gapSpace: 6
    }),
    [columnId, originalData]
  )

  return (
    <HoverCard
      plainCardProps={plainCardProps}
      type={HoverCardType.plain}
      cardDismissDelay={500}
    >
      <Link>
        <USD value={value} />
      </Link>
    </HoverCard>
  )
}
