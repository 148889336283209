import { css } from '@emotion/react'
import { flexRender, Header, Row } from '@tanstack/react-table'
import { IBalanceDetailResponseValueItem } from 'modules/Advisory/modules/Rdot360/store/balancesApi/IBalanceDetailResponse'
import { FC } from 'react'
import { SnackBar } from 'shared/uiSharedComponents/Snackbar'
import { getBorderStyles } from '../../../features/Loans/getBorderStyles'
import { getTableStyles } from '../../../features/Loans/getTableStyles'
import { TableCell } from '../../../features/Loans/TableCellsGeneric'

const classes = getTableStyles()

interface IHeadingProp {
  headers: Header<IBalanceDetailResponseValueItem, unknown>[]
}

const THead: FC<IHeadingProp> = ({ headers }) => (
  <table css={[classes.tableBase, classes.tableHeader]}>
    <thead>
      <tr>
        {headers?.map((header, i) => (
          <th
            key={i}
            css={[
              { width: header.column.columnDef.size },
              getBorderStyles(header.column.id)
            ]}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        ))}
      </tr>
    </thead>
  </table>
)

const TFoot: FC<IHeadingProp> = ({ headers }) => (
  <table css={[classes.tableBase, classes.tableFooter]}>
    <tfoot>
      <tr>
        {headers?.map((header, i) => (
          <td
            key={i}
            css={[
              { width: header.column.columnDef.size },
              getBorderStyles(header.column.id)
            ]}
          >
            {flexRender(header.column.columnDef.footer, header.getContext())}
          </td>
        ))}
      </tr>
    </tfoot>
  </table>
)

interface IBodyProp {
  rows: Row<IBalanceDetailResponseValueItem>[]
}

const TBody: FC<IBodyProp> = ({ rows }) =>
  rows.map((row) => {
    const rowDepth = row.depth
    if (rowDepth !== 0) {
      return null
    }
    const visibleCells = row.getVisibleCells()
    const isExpanded = row.getIsExpanded()
    const subRows = row.subRows

    return (
      <table key={row.id} css={[classes.tableBase, classes.tableContent]}>
        <tbody>
          <tr>
            {visibleCells.map((cell) => {
              const columnId = cell.column.id
              const isPlaceholder =
                columnId === 'display' && subRows.length < 2 ? true : undefined

              return (
                <TableCell
                  key={cell.id}
                  tableCell={cell}
                  styles={getBorderStyles(columnId, '#E6E6E6')}
                  className={cell.column.columnDef.meta?.className}
                  isPlaceholder={isPlaceholder}
                />
              )
            })}
          </tr>

          {subRows.length > 1 &&
            isExpanded &&
            row.subRows.map((subRow, i) => {
              const subVisibleCells = subRow.getVisibleCells()
              const isEven = i % 2 === 0
              const subRowStyles = css({
                backgroundColor: isEven ? '#FFFFFF' : '#FAFAFA'
              })

              return (
                <tr key={subRow.id} className="headerRow" css={subRowStyles}>
                  {subVisibleCells.map((cell) => (
                    <TableCell
                      key={cell.id}
                      tableCell={cell}
                      styles={getBorderStyles(cell.column.id, '#E6E6E6')}
                      className={cell.column.columnDef.meta?.className}
                    />
                  ))}
                </tr>
              )
            })}
        </tbody>
      </table>
    )
  })

export const ThirdPartyLoansTable: FC<
  IHeadingProp & IBodyProp & { showNoData?: boolean; error?: Error }
> = ({ headers, rows, showNoData, error }) => (
  <>
    <THead headers={headers} />
    {error || showNoData ? (
      <div
        css={{
          paddingTop: 10,
          paddingBottom: 20
        }}
      >
        {error && (
          <SnackBar
            type="Failure"
            message={error.message || 'An unknown error occurred'}
          />
        )}
        {showNoData && <SnackBar type="Info" message="No data available" />}
      </div>
    ) : (
      <>
        <TBody rows={rows} />
        {rows.length > 0 && <TFoot headers={headers} />}
      </>
    )}
  </>
)
