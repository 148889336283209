import { ActionButton } from '@fluentui/react'

interface IExpandAllRowsProps {
  canSomeRowsExpand?: boolean
  onClick?: () => (event: unknown) => void
  isAllRowsExpanded?: boolean
  isFetching?: boolean
  showNoData?: boolean
}

export const ExpandAllRows: React.FC<IExpandAllRowsProps> = ({
  canSomeRowsExpand,
  onClick = () => () => null,
  isAllRowsExpanded,
  isFetching,
  showNoData
}) => {
  return (
    <div css={{ minHeight: 40 }}>
      {canSomeRowsExpand && (
        <ActionButton
          label="Expand All Rows"
          onClick={onClick()}
          styles={{
            root: {
              textDecoration: 'underline'
            }
          }}
          disabled={isFetching || showNoData}
        >
          {!isAllRowsExpanded ? 'Expand' : 'Collapse'} All
        </ActionButton>
      )}
    </div>
  )
}
