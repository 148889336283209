import { css } from '@emotion/react'
import { flexRender, Header, Row } from '@tanstack/react-table'
import { FC } from 'react'
import { SnackBar } from 'shared/uiSharedComponents/Snackbar'
import { getBorderStyles, getMLBorderStyles } from '../getBorderStyles'
import { getTableStyles } from '../getTableStyles'
import { TableCell } from '../TableCellsGeneric'
import { ForeignCurrencyDetails } from './ForeignCurrencyDetails'
import type { ICashBalanceDetails } from './getModalData'
import { meetsForeignCurrencyCondition } from './useReactTableProps'

interface IHeadingProp {
  headers: Header<ICashBalanceDetails, unknown>[]
}

interface IBodyProp {
  rows: Row<ICashBalanceDetails>[]
  showNoData?: boolean
}

const classes = getTableStyles()

const THead: FC<IHeadingProp> = ({ headers }) => (
  <table
    css={[
      classes.tableBase,
      classes.tableHeader,
      { top: 0, minWidth: 'unset' }
    ]}
  >
    <thead>
      <tr>
        {headers?.map((header, i) => {
          const { size, meta, header: columnHeader } = header.column.columnDef

          return (
            <th
              key={i}
              css={[{ width: size }, getMLBorderStyles(header.column.id)]}
              className={meta?.className}
            >
              {flexRender(columnHeader, header.getContext())}
            </th>
          )
        })}
      </tr>
    </thead>
  </table>
)

const TFoot: FC<IHeadingProp> = ({ headers }) => (
  <table css={[classes.tableBase, classes.tableFooter]}>
    <tfoot>
      <tr>
        {headers?.map((header, i) => {
          const { size, meta, footer } = header.column.columnDef
          return (
            <td
              key={i}
              css={[{ width: size }, getBorderStyles(header.column.id)]}
              className={meta?.className}
            >
              {flexRender(footer, header.getContext())}
            </td>
          )
        })}
      </tr>
    </tfoot>
  </table>
)

const TBody: FC<IBodyProp> = ({ rows }) =>
  rows.map((row) => {
    if (row.depth !== 0) {
      return null
    }
    const visibleCells = row.getVisibleCells()
    const isExpanded = row.getIsExpanded()
    const rowOriginal = row.original

    return (
      <table key={row.id} css={[classes.tableBase, classes.tableContent]}>
        <tbody>
          <tr>
            {visibleCells.map((cell) => (
              <TableCell
                key={cell.id}
                tableCell={cell}
                styles={getMLBorderStyles(cell.column.id, '#E6E6E6')}
                className={cell.column.columnDef.meta?.className}
              />
            ))}
          </tr>

          {isExpanded && meetsForeignCurrencyCondition(rowOriginal) && (
            <ForeignCurrencyDetails
              foreignCurrency={rowOriginal.foreignCurrency}
            />
          )}

          {isExpanded &&
            row.subRows.map((subRow, i) => {
              const isAccountKeySelected = subRow.original.isKeySelected
              const subVisibleCells = subRow.getVisibleCells()
              const subVisibleCellsSliced = isAccountKeySelected
                ? subVisibleCells.slice(1)
                : subVisibleCells
              const isEven = i % 2 === 0
              const subRowStyles = css({
                backgroundColor: isEven ? '#FFFFFF' : '#FAFAFA'
              })

              return (
                <tr key={subRow.id} className="headerRow" css={subRowStyles}>
                  {isAccountKeySelected && (
                    <td className="selectedAccountKey">&#9679;</td>
                  )}
                  {subVisibleCellsSliced.map((cell) => (
                    <TableCell
                      key={cell.id}
                      tableCell={cell}
                      styles={getMLBorderStyles(cell.column.id, '#E6E6E6')}
                    />
                  ))}
                </tr>
              )
            })}
        </tbody>
      </table>
    )
  })

export const CashModalTable: FC<IHeadingProp & IBodyProp> = ({
  headers,
  rows,
  showNoData
}) => {
  const canExpandRows = rows.some((row) => row.getCanExpand())
  if (!canExpandRows) {
    headers = headers.slice(1)
    rows = rows.map((row) => {
      const newRow = { ...row }
      newRow.getVisibleCells = () => row.getVisibleCells().slice(1)
      return newRow
    })
  }

  return (
    <>
      <THead headers={headers} />
      {showNoData ? (
        <div
          css={{
            paddingTop: 10,
            paddingBottom: 20
          }}
        >
          <SnackBar type="Info" message="No data available" />
        </div>
      ) : (
        <>
          <TBody rows={rows} />
          <TFoot headers={headers} />
        </>
      )}
    </>
  )
}
