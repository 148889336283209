import { FC, useMemo } from 'react'
import { IBalanceDetailResponseValueItem } from '../../../store/balancesApi/IBalanceDetailResponse'
import { BubbleWrap } from './BubbleWrap'
import { ListItem } from './ListItem'

export const MarginDetails: FC<{
  originalData?: IBalanceDetailResponseValueItem
}> = ({ originalData }) => {
  const {
    fedcall,
    fedoptionrequirement,
    fedsecurityrequirement,
    housecall,
    houseoptionrequirement,
    housesecurityrequirement,
    housesurplus,
    marginavailwdrcondition,
    margincredit,
    margindebit,
    marginequity,
    marginnetmarketvalue,
    minimumequitycall,
    minimummarginequity,
    sma
  } = originalData || {}

  const CommonDetails = useMemo(
    () => (
      <>
        <div css={{ padding: 4, marginBottom: 10, fontWeight: 700 }}>
          {marginavailwdrcondition?.replace(/([a-z])([A-Z])/g, '$1 $2')}
        </div>
        <ListItem label="Net Market Value" value={marginnetmarketvalue} />
        <ListItem label="Margin Debit Balance" value={margindebit} shaded />
        <ListItem label="Margin Credit Balance" value={margincredit} />
        <ListItem label="Margin Equity" value={marginequity} spotlit />
      </>
    ),
    [
      marginavailwdrcondition,
      margincredit,
      margindebit,
      marginequity,
      marginnetmarketvalue
    ]
  )

  const FedAvailabilityDetails = useMemo(
    () => (
      <>
        <ListItem
          label="Fed Security Requirement"
          value={fedsecurityrequirement}
        />
        <ListItem
          label="Fed Option Requirement"
          value={fedoptionrequirement}
          shaded
        />
        <ListItem label="SMA" value={sma} spotlit />
        <ListItem label="House Surplus" value={housesurplus} spotlit />
      </>
    ),
    [fedoptionrequirement, fedsecurityrequirement, housesurplus, sma]
  )

  const HouseAvailabilityDetails = useMemo(
    () => (
      <>
        <ListItem
          label="House Security Requirement"
          value={housesecurityrequirement}
        />
        <ListItem
          label="House Option Requirement"
          value={houseoptionrequirement}
          shaded
        />
        <ListItem label="House Surplus" value={housesurplus} spotlit />
        <ListItem label="SMA" value={sma} spotlit />
      </>
    ),
    [housesecurityrequirement, houseoptionrequirement, housesurplus, sma]
  )

  const FedCallDetails = useMemo(
    () => (
      <>
        <ListItem
          label="Fed Security Requirement"
          value={fedsecurityrequirement}
        />
        <ListItem
          label="Fed Option Requirement"
          value={fedoptionrequirement}
          shaded
        />
        <ListItem label="Fed Call" value={fedcall} spotlit />
        {housesurplus != null && (
          <ListItem label="House Surplus" value={housesurplus} spotlit />
        )}
        {housecall != null && (
          <ListItem label="House Call" value={housecall} spotlit />
        )}
      </>
    ),
    [
      fedsecurityrequirement,
      fedoptionrequirement,
      fedcall,
      housesurplus,
      housecall
    ]
  )

  const HouseCallDetails = useMemo(
    () => (
      <>
        <ListItem
          label="House Security Requirement"
          value={housesecurityrequirement}
        />
        <ListItem
          label="House Option Requirement"
          value={houseoptionrequirement}
          shaded
        />
        <ListItem label="House Call" value={housecall} spotlit />
        {sma != null && <ListItem label="SMA" value={sma} spotlit />}
        {fedcall != null && (
          <ListItem label="Fed Call" value={fedcall} spotlit />
        )}
      </>
    ),
    [housesecurityrequirement, houseoptionrequirement, housecall, sma, fedcall]
  )

  const EquityCallDetails = useMemo(
    () => (
      <>
        <ListItem label="Minimum Margin Equity" value={minimummarginequity} />
        <ListItem
          label="Minimum Equity Call"
          value={minimumequitycall}
          shaded
        />
      </>
    ),
    [minimummarginequity, minimumequitycall]
  )

  return (
    <BubbleWrap>
      {CommonDetails}

      {(() => {
        switch (marginavailwdrcondition) {
          case 'FedAvailability':
            return FedAvailabilityDetails
          case 'HouseAvailability':
            return HouseAvailabilityDetails
          case 'FedCall':
            return FedCallDetails
          case 'HouseCall':
            return HouseCallDetails
          case 'EquityCall':
            return EquityCallDetails
          default:
            return null
        }
      })()}

      <div css={{ padding: 4 }}>
        <em>*Use lower of the SMA or House Surplus</em>
      </div>
    </BubbleWrap>
  )
}
