import { Row } from '@tanstack/react-table'
import { isNotNullOrEmpty } from 'shared/guards'
import { IBalanceDetailResponseValueItem } from '../../../store/balancesApi/IBalanceDetailResponse'

export const globalFilterFn = (
  row: Row<IBalanceDetailResponseValueItem>,
  id: string,
  filterValue: string
): boolean => {
  if (!isNotNullOrEmpty(filterValue)) {
    return true
  }
  const searchString = (filterValue || '').toLowerCase()
  const {
    customername = '',
    collateralaccounts = '',
    key = '',
    loanaccount = ''
  } = row.original
  const searchableProperties = [
    customername,
    collateralaccounts,
    key,
    loanaccount
  ]

  return searchableProperties.some((value) =>
    value?.toLowerCase().includes(searchString)
  )
}
