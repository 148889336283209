import { IColumnDefinition } from '../core/contracts/IColumnDefinition'
import { CommonColumnIdsEnum } from './CommonColumnIdsEnum'

export const BaseColumnDefinitions: Record<string, IColumnDefinition> = {
  [CommonColumnIdsEnum.aus]: {
    id: CommonColumnIdsEnum.aus,
    name: 'Assets Under Supervision',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.aum]: {
    id: CommonColumnIdsEnum.aum,
    name: 'Managed Assets',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.annuity]: {
    id: CommonColumnIdsEnum.annuity,
    name: 'Annuities & Insurance',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.brokerageAssets]: {
    id: CommonColumnIdsEnum.brokerageAssets,
    name: 'Brokerage Assets',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.loansOutstanding]: {
    id: CommonColumnIdsEnum.loansOutstanding,
    name: 'Loans Outstanding',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.purchasingPower]: {
    id: CommonColumnIdsEnum.purchasingPower,
    name: 'Cash Available To Trade',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.legalEntityName]: {
    id: CommonColumnIdsEnum.legalEntityName,
    name: 'Legal Entity Name',
    dataPath: 'LegalEntityName',
    type: 'string',
    width: 220,
    searchable: true,
    filterable: true,
    sortable: true
  },
  [CommonColumnIdsEnum.NNM_MTDNet]: {
    id: CommonColumnIdsEnum.NNM_MTDNet,
    name: 'NNM - MTD Net',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_MTDInflow]: {
    id: CommonColumnIdsEnum.NNM_MTDInflow,
    name: 'NNM - MTD Inflows',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_MTDOutflow]: {
    id: CommonColumnIdsEnum.NNM_MTDOutflow,
    name: 'NNM - MTD Outflows',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_QTDNet]: {
    id: CommonColumnIdsEnum.NNM_QTDNet,
    name: 'NNM - QTD Net',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_QTDInflow]: {
    id: CommonColumnIdsEnum.NNM_QTDInflow,
    name: 'NNM - QTD Inflows',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_QTDOutflow]: {
    id: CommonColumnIdsEnum.NNM_QTDOutflow,
    name: 'NNM - QTD Outflows',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_YTDNet]: {
    id: CommonColumnIdsEnum.NNM_YTDNet,
    name: 'NNM - YTD Net',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_YTDInflow]: {
    id: CommonColumnIdsEnum.NNM_YTDInflow,
    name: 'NNM - YTD Inflows',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_YTDOutflow]: {
    id: CommonColumnIdsEnum.NNM_YTDOutflow,
    name: 'NNM - YTD Outflows',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_PriorDayNet]: {
    id: CommonColumnIdsEnum.NNM_PriorDayNet,
    name: 'NNM - Prior Day Net',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_PriorDayInflow]: {
    id: CommonColumnIdsEnum.NNM_PriorDayInflow,
    name: 'NNM - Prior Day Inflows',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_PriorDayOutflow]: {
    id: CommonColumnIdsEnum.NNM_PriorDayOutflow,
    name: 'NNM - Prior Day Outflows',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_PriorYearNet]: {
    id: CommonColumnIdsEnum.NNM_PriorYearNet,
    name: 'NNM - Prior Year Net',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_PriorYearInflow]: {
    id: CommonColumnIdsEnum.NNM_PriorYearInflow,
    name: 'NNM - Prior Year Inflows',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.NNM_PriorYearOutflow]: {
    id: CommonColumnIdsEnum.NNM_PriorYearOutflow,
    name: 'NNM - Prior Year Outflows',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  }
}
