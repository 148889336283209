export interface IBalanceDetailResponseValue {
  accountbalances: IBalanceDetailResponseValueItem[]
  marginbalances: IBalanceDetailResponseValueItem[]
}

export interface IForeignCurrency {
  currencycode?: string
  localvalue?: number
  usdequivalent?: number
  sdlocalvalue?: number
  sdusdequivalent?: number
}

export interface IBalanceDetailResponseValueItem {
  '@odata.type'?: string
  customername?: string
  collateralaccounts?: string
  // "loanpurpose": null,
  loanaccount?: string
  totalcredit?: number
  availablecredit?: number
  // "grosscollateralvalue": null,
  interestrate?: number
  rateindex?: string
  // "spread": null,
  // "billedamount": null,
  // "dayofmonthpaymentdue": null,
  nextpaymentdate?: string
  nextpayment?: number
  // "paymentmethod": null,
  // "interestpaidamountytd": null,
  nextratechangedate?: string
  loanopendate?: string
  // "relationshipmanager": null,
  // "servicingphone": null,
  minimumequitycall?: number
  minimummarginequity?: number
  ismultimargin?: boolean
  isprimary?: boolean
  marginavailwdrcondition?: string
  marginnetmarketvalue?: number
  multimargindetails?: IBalanceDetailResponseValueItem[]
  accountaumber?: string
  isnote?: boolean
  availablewithdraw?: number
  marginintrate?: number
  marginaccruedinterest?: number
  cashmarginavailabletowithdraw?: number
  cashbuyingpower?: number
  openordercashbuyingpower?: number
  cashmarginbuyingpower?: number
  nonmarginbuyingpower?: number
  key?: string
  asofdate?: string
  totalaccountvalue?: number
  pricedinvestments?: number
  cashmoneyaccounts?: number
  outstandingbalance?: number
  annuity?: number
  moneyaccountvalue?: number
  cashbalance?: number
  marginbalance?: number
  pendingactivity?: number
  fixedincomeaccruedinterest?: number
  availablemarginbalance?: number
  othercredit?: number
  othercreditdebit?: number
  othercreditdebitchange?: number
  marginandoutstandingbalance?: number
  margindebitcoveredbycash?: number
  margindebitoutstanding?: number
  margindebit?: number
  margincredit?: number
  marginequity?: number
  totalaccountvaluechange?: number
  pricedinvestmentschange?: number
  cashmoneyaccountschange?: number
  moneyaccountvaluechange?: number
  cashbalancechange?: number
  marginbalancechange?: number
  fixedincomeaccruedinterestchange?: number
  availablemarginbalancechange?: number
  outstandingbalancechange?: number
  annuitychange?: number
  totalaccountvaluepercentchange?: number
  pricedinvestmentspercentchange?: number
  todayschange?: IBalanceTodaysChange
  cashmoneyaccountspercentchange?: number
  moneyaccountvaluepercentchange?: number
  cashbalancepercentchange?: number
  fixedincomeaccruedinterestpercentchange?: number
  availablemarginbalancepercentchange?: number
  outstandingbalancepercentchange?: number
  annuitypercentchange?: number
  piasofdate?: string
  cashprincipal?: number
  cashincome?: number
  notes?: IBalanceDetailResponseValueItem[]
  status?: IBalanceDetailResponseValueItemStatus
  totalassetaccountvalue?: number
  totalassetaccountvaluechange?: number
  totalotherassetsvaluechange?: number
  totalexternalaccountvaluechange?: number
  dividendinterest_type9?: number
  totalNonNfsValueChange?: number
  holdingAccruDividends?: number | null
  holdingAccruIncome?: number | null
  holdingAccruInterest?: number | null
  fedsecurityrequirement?: number
  fedoptionrequirement?: number
  fedcall?: number
  housesecurityrequirement?: number
  houseoptionrequirement?: number
  housesurplus?: number
  housecall?: number
  sma?: number
  shortcredit?: number
  uncollectedfunds?: number
  shortputcashrequirement?: number
  foreigncurrency?: IForeignCurrency[]
  sdcashbalance?: number
  sdmargincredit?: number
  sdshortcredit?: number
  sddividendinterest_type9?: number
  sdothercredit?: number
}

export interface IBalanceTodaysChange {
  RCM?: IBalanceTodaysChangeItem[]
  External?: unknown[]
  Other?: unknown[]
}

export interface IBalanceTodaysChangeItem {
  category: string
  amount: number
  sortOrder: number
  Activity: IBalanceTodaysChangeItemActivity[]
}

export interface IBalanceTodaysChangeItemActivity {
  description: TodaysChangeKeys
  todaysChangeAmount: number
}

export const todaysChangeFields = {
  gainOrLoss: 'Gain/Loss',
  accruedIncome: 'Accrued Income (Div/Int)',
  deposits: 'Deposits',
  interestOrDividends: 'Interest/Dividends',
  withdrawals: 'Withdrawals',
  other: 'Other'
} as const

export const todaysChangeFieldsDisplay = {
  [todaysChangeFields.gainOrLoss]: todaysChangeFields.gainOrLoss,
  [todaysChangeFields.accruedIncome]: todaysChangeFields.accruedIncome,
  [todaysChangeFields.deposits]: todaysChangeFields.deposits,
  [todaysChangeFields.interestOrDividends]: 'Dividends / Interest Paid',
  [todaysChangeFields.withdrawals]: todaysChangeFields.withdrawals,
  [todaysChangeFields.other]: todaysChangeFields.other
} as const

export type TodaysChangeKeys =
  (typeof todaysChangeFields)[keyof typeof todaysChangeFields]

export interface IBalanceDetailResponseValueItemStatus {
  Severity: unknown
  Code: string
  Text: unknown
  Errors: unknown[]
}
