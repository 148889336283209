import { Modal } from '@fluentui/react'
import { sumBy } from 'lodash'
import { useMemo, useState } from 'react'
import { Icon } from 'shared/uiSharedComponents/Icon'
import { Link } from '../../../components/shared/Link'
import { IBalanceDetailResponseValueItem } from '../../../store/balancesApi/IBalanceDetailResponse'
import { USD } from '../formatting'
import { CashModalContent } from './CashModalContent'

export const CashModal: React.FC<{
  value?: number
  rowData?: IBalanceDetailResponseValueItem
  accountKey?: string
  isAccountPrimary?: boolean
}> = ({ value, rowData, accountKey, isAccountPrimary }) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const {
    cashbalance = 0,
    margincredit = 0,
    shortcredit = 0,
    dividendinterest_type9 = 0,
    moneyaccountvalue = 0,
    othercredit = 0,
    foreigncurrency = [],
    ismultimargin
  } = rowData || {}

  const usdequivalentTotal = sumBy(
    foreigncurrency,
    ({ usdequivalent }) => usdequivalent ?? 0
  )

  const tradeDateBalanceTotal =
    cashbalance +
    margincredit +
    shortcredit +
    dividendinterest_type9 +
    moneyaccountvalue +
    othercredit +
    usdequivalentTotal

  const CashModalContentProps = useMemo(
    () => ({
      rowData,
      accountKey,
      isAccountPrimary
    }),
    [rowData, accountKey, isAccountPrimary]
  )

  return (
    <>
      <Link onClick={openModal}>
        <USD value={value} />
      </Link>

      <Modal isOpen={isModalOpen} onDismiss={closeModal} isBlocking={false}>
        <div css={{ margin: 20, maxWidth: 1200, maxHeight: 800 }}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: 10,
              borderBottom: '1px solid lightgray'
            }}
          >
            <div css={{ fontSize: 18 }}>
              <span>
                {ismultimargin ? 'Multi' : 'Single'} Margin Cash Details
              </span>{' '}
              | <span css={{ fontWeight: 300 }}>Trade Date Balance:</span>{' '}
              <span css={{ fontWeight: 600 }}>
                <USD value={tradeDateBalanceTotal} />
              </span>
            </div>
            <Icon
              type="CancelClose"
              width={16}
              height={16}
              title="Close Modal"
              onClick={closeModal}
            />
          </div>
          <CashModalContent {...CashModalContentProps} />
        </div>
      </Modal>
    </>
  )
}
