import { sumBy } from 'lodash'
import { FC } from 'react'
import { IBalanceDetailResponseValueItem } from '../../../store/balancesApi/IBalanceDetailResponse'
import { BubbleWrap } from './BubbleWrap'
import { ListItem } from './ListItem'

export const CashDetails: FC<{
  originalData?: IBalanceDetailResponseValueItem
}> = ({ originalData }) => {
  const {
    availablewithdraw,
    cashmoneyaccounts,
    dividendinterest_type9,
    foreigncurrency,
    marginaccruedinterest,
    margincredit,
    pendingactivity,
    shortcredit,
    shortputcashrequirement,
    uncollectedfunds
  } = originalData || {}

  const foreignCurrencyTotal = sumBy(
    foreigncurrency,
    ({ usdequivalent }) => usdequivalent || 0
  )

  return (
    <BubbleWrap>
      <ListItem label="Total Cash Balance" value={cashmoneyaccounts} />
      <ListItem
        label="Margin (Type 2) Credit Balance"
        value={margincredit}
        shaded
      />
      <ListItem label="Short (Type 3) Balance" value={shortcredit} />
      <ListItem
        label="Foreign Currency (USDE)"
        value={foreignCurrencyTotal}
        shaded
      />
      <ListItem
        label="Accumulated Dividends and Interest"
        value={dividendinterest_type9}
      />
      <ListItem
        label="Short Put Cash Requirement"
        value={shortputcashrequirement}
        shaded
      />
      <ListItem label="Uncollected Funds" value={uncollectedfunds} />
      <ListItem
        label="Accumulated Interest Charge"
        value={marginaccruedinterest}
        shaded
      />
      <ListItem label="Pending Activity" value={pendingactivity} />
      <ListItem
        label="Cash Available to Withdraw"
        value={availablewithdraw}
        spotlit
      />
    </BubbleWrap>
  )
}
