import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table'
import { sortBy } from 'lodash'
import { useMemo, useState } from 'react'
import { useDebounce } from 'shared/hooks/useDebounce'
import { IBalanceDetailResponseValueItem } from '../../../store/balancesApi/IBalanceDetailResponse'
import { useRdot360BalancesContext } from '../../../store/rdot360Context/useRdot360BalancesContext'
import { getColumnDefinitions } from './ColumnDefinitions'
import { globalFilterFn } from './globalFilterFn'
import { useMarginLoansUiState } from './store'

const columns = getColumnDefinitions()

export const useReactTableProps = () => {
  const {
    searchText,
    setSearchText,
    expanded,
    setExpanded,
    sorting,
    setSorting
  } = useMarginLoansUiState()

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const debouncedSearchText = useDebounce(searchText, 100)
  const {
    balancesResponse,
    isFetching,
    isUninitialized,
    invalidateCache,
    error
  } = useRdot360BalancesContext()

  const tableData: IBalanceDetailResponseValueItem[] = useMemo(() => {
    const { marginbalances = [] } = balancesResponse || {}
    return marginbalances
  }, [balancesResponse])

  const getSubRows = (row: IBalanceDetailResponseValueItem) => {
    return sortBy(row.multimargindetails || [], [(detail) => !detail.isprimary])
  }

  const tableOptions = useMemo(
    () => ({
      data: tableData,
      columns,
      state: {
        sorting,
        expanded,
        globalFilter: debouncedSearchText,
        columnVisibility
      },
      autoResetExpanded: false,
      groupedColumnMode: undefined,
      getExpandedRowModel: getExpandedRowModel(),
      getGroupedRowModel: getGroupedRowModel(),
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getSubRows,
      onExpandedChange: setExpanded,
      onGlobalFilterChange: setSearchText,
      onSortingChange: setSorting,
      onColumnVisibilityChange: setColumnVisibility,
      globalFilterFn
    }),
    [
      tableData,
      sorting,
      expanded,
      debouncedSearchText,
      columnVisibility,
      setExpanded,
      setSearchText,
      setSorting
    ]
  )

  const table = useReactTable(tableOptions)
  const { rows } = table.getRowModel()
  const canSomeRowsExpand = rows.some((row) => row.getCanExpand())
  const headers = table.getFlatHeaders()
  const { getToggleAllRowsExpandedHandler } = table
  const isAllRowsExpanded = table.getIsAllRowsExpanded()
  const isSomeRowsExpanded = table.getIsSomeRowsExpanded()
  const showNoData = useMemo(
    () => !isUninitialized && !isFetching && !error && !rows.length,
    [error, isFetching, isUninitialized, rows.length]
  )

  /* useEffect(() => {
    console.log('Some:', isSomeRowsExpanded, 'All:', isAllRowsExpanded)
  }, [isSomeRowsExpanded, isAllRowsExpanded]) */

  return {
    canSomeRowsExpand,
    getToggleAllRowsExpandedHandler,
    headers,
    invalidateCache,
    isAllRowsExpanded,
    isSomeRowsExpanded,
    isFetching,
    rows,
    searchText,
    setSearchText,
    sorting,
    setSorting,
    error,
    showNoData
  }
}
