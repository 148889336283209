import { createColumnHelper } from '@tanstack/react-table'
import { textAlignLeft } from '../styles'
import {
  CashBalanceDetailsCell,
  ColumnHeaderWithSortIndicator,
  DisplayCell,
  USDCell,
  USDCellTotalAmount
} from '../TableCellsGeneric'
import type { ICashBalanceDetails } from './getModalData'

const columnHelper = createColumnHelper<ICashBalanceDetails>()

export const getColumnDefinitions = () => [
  columnHelper.display({
    id: 'display',
    cell: DisplayCell,
    aggregatedCell: DisplayCell,
    size: 12,
    meta: { className: 'displayCell' }
  }),
  columnHelper.accessor(
    ({ cashBalanceType, accountKey }) => [cashBalanceType, accountKey],
    {
      id: 'accountKey',
      header: (props) => (
        <ColumnHeaderWithSortIndicator {...props} leftAligned>
          Cash Balance Details
        </ColumnHeaderWithSortIndicator>
      ),
      cell: CashBalanceDetailsCell,
      aggregatedCell: CashBalanceDetailsCell,
      size: 180,
      footer: () => <div css={textAlignLeft}>Total</div>
    }
  ),
  columnHelper.accessor(({ settelmentDateBalance }) => settelmentDateBalance, {
    id: 'settelmentDateBalance',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Settelment Date Balance
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell,
    footer: USDCellTotalAmount
  }),
  columnHelper.accessor(({ pendingActivity }) => pendingActivity, {
    id: 'pendingActivity',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Pending Activity
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell,
    footer: USDCellTotalAmount
  }),
  columnHelper.accessor(({ tradeDateBalance }) => tradeDateBalance, {
    id: 'tradeDateBalance',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Trade Date Balance
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell,
    footer: USDCellTotalAmount
  })
]
