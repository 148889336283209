import { Stack } from '@fluentui/react'
import React from 'react'
import { IHousehold } from '../../../api/household.types'
import { HouseholdNameCell } from '../../Households/HouseholdNameCell'
import { AusCell } from '../core/components/AusCell'
import { DateCell } from '../core/components/DateCell'
import { IListsCellComponent } from '../core/components/ListsDataTable'
import { TextCell } from '../core/components/TextCell'
import { USDCell } from '../core/components/USDCell'
import { AssetAllocationBarContainer } from '../shared/AssetAllocationBarContainer'
import { ProductAllocationBarContainer } from '../shared/ProductAllocationBarContainer'
import { HouseholdColumnIds } from './ColumnDefinitions'

export const createHouseholdCell = (cellComponents: {
  [key: string]: React.FC<{ household: IHousehold }>
}) => {
  const HouseholdCellComponent: IListsCellComponent<IHousehold> = ({
    columnDefinition,
    item
  }) => {
    const Cell = cellComponents[columnDefinition.id] || null
    return Cell && <Cell household={item} />
  }
  return HouseholdCellComponent
}

export const HouseholdCellComponents: {
  [key: string]: React.FC<{ household: IHousehold }>
} = {
  [HouseholdColumnIds.team]: ({ household }) => (
    <Stack>
      {household?.Advisors?.map(({ ClientAdvisorTeam }, i) => (
        <TextCell key={i}>{ClientAdvisorTeam || ''}</TextCell>
      ))}
    </Stack>
  ),
  [HouseholdColumnIds.clientAdvisor]: ({ household }) => (
    <Stack>
      {household?.Advisors?.map(({ ClientAdvisor }, i) => (
        <TextCell key={i}>{ClientAdvisor || ''}</TextCell>
      ))}
    </Stack>
  ),
  [HouseholdColumnIds.clientAdvisorId]: ({ household }) => (
    <Stack>
      {household?.Advisors?.map(({ ClientAdvisorID }, i) => (
        <TextCell key={i}>{ClientAdvisorID || ''}</TextCell>
      ))}
    </Stack>
  ),
  [HouseholdColumnIds.Office]: ({ household }) => (
    <Stack>
      {household?.Advisors?.map(({ HubName }, i) => (
        <TextCell key={i}>{HubName || ''}</TextCell>
      ))}
    </Stack>
  ),
  [HouseholdColumnIds.Division]: ({ household }) => (
    <Stack>
      {household?.Advisors?.map(({ Division }, i) => (
        <TextCell key={i}>{Division || ''}</TextCell>
      ))}
    </Stack>
  ),
  [HouseholdColumnIds.aum]: ({ household }) => (
    <USDCell value={household?.householdKPI?.AumManaged} fractionDigits={0} />
  ),
  [HouseholdColumnIds.aus]: ({ household }) => (
    <AusCell KPI={household.householdKPI} />
  ),
  [HouseholdColumnIds.brokerageAssets]: ({ household }) => (
    <USDCell value={household?.householdKPI?.AumBrokerage} fractionDigits={0} />
  ),
  [HouseholdColumnIds.purchasingPower]: ({ household }) => (
    <USDCell
      value={household?.householdKPI?.cashAvlToTrade}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.loansOutstanding]: ({ household }) => (
    <USDCell
      value={household?.householdKPI?.LoanOutstanding}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.annuity]: ({ household }) => (
    <USDCell value={household?.householdKPI?.annuity} fractionDigits={0} />
  ),
  [HouseholdColumnIds.lastUpdated]: ({ household }) => (
    <DateCell value={household?.LastUpdatedAt} />
  ),
  [HouseholdColumnIds.lastModified]: ({ household }) => (
    <DateCell value={household?.lastUpdatedDate} />
  ),
  [HouseholdColumnIds.created]: ({ household }) => (
    <DateCell value={household?.createdDate} />
  ),
  [HouseholdColumnIds.productAllocation]: ({ household }) => (
    <ProductAllocationBarContainer
      productAllocations={household?.productAllocation || []}
    />
  ),
  [HouseholdColumnIds.assetAllocation]: ({ household }) => (
    <AssetAllocationBarContainer
      assetAllocations={household?.assetAllocationLvl1 || []}
    />
  ),
  [HouseholdColumnIds.householdId]: ({ household }) => (
    <TextCell>{household?.householdId}</TextCell>
  ),
  [HouseholdColumnIds.householdName]: ({ household }) => (
    <HouseholdNameCell household={household} />
  ),
  [HouseholdColumnIds.householdTags]: ({ household }) => (
    <Stack style={{ minWidth: 0 }}>
      {household.HouseholdTags?.map((t) => (
        <TextCell key={t.Id} title={t.Name}>
          {t.Name}
        </TextCell>
      ))}
    </Stack>
  ),
  [HouseholdColumnIds.revenueMTD]: ({ household }) => (
    <USDCell value={household?.revenue?.MTDcompRevenue} fractionDigits={0} />
  ),
  [HouseholdColumnIds.revenueQTD]: ({ household }) => (
    <USDCell value={household?.revenue?.QTDcompRevenue} fractionDigits={0} />
  ),
  [HouseholdColumnIds.revenueYTD]: ({ household }) => (
    <USDCell value={household?.revenue?.YTDcompRevenue} fractionDigits={0} />
  ),
  [HouseholdColumnIds.revenueT12]: ({ household }) => (
    <USDCell value={household?.revenue?.ttmrevenue} fractionDigits={0} />
  ),
  [HouseholdColumnIds.MonthlyAvgSweepBal]: ({ household }) => (
    <USDCell value={household.MonthlyAvgSweepBal} fractionDigits={0} />
  ),
  [HouseholdColumnIds.AdvisedOnly]: ({ household }) => (
    <USDCell value={household.householdKPI?.AdvisedOnly} fractionDigits={0} />
  ),
  [HouseholdColumnIds.LoanOutstandingOutsideNfs]: ({ household }) => (
    <USDCell
      value={household.householdKPI?.LoanOutstandingOutsideNfs}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.LoanOutstandingNfs]: ({ household }) => (
    <USDCell
      value={household.householdKPI?.LoanOutstandingNfs}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.AdminReporting]: ({ household }) => (
    <USDCell
      value={household.householdKPI?.AdminReportingAssets}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.activeInd]: ({ household }) => (
    <TextCell>{household.ActiveFlag}</TextCell>
  ),
  [HouseholdColumnIds.employeeInd]: ({ household }) => (
    <TextCell>{household.employeeCode}</TextCell>
  ),
  [HouseholdColumnIds.billingAUS]: ({ household }) => (
    <USDCell value={household?.householdKPI?.AumTotal_NFS} fractionDigits={0} />
  ),
  [HouseholdColumnIds.RockConnectAccBalance]: ({ household }) => (
    <USDCell value={household?.RockConnectAccBalance} fractionDigits={0} />
  ),
  [HouseholdColumnIds.assets_liabilities]: ({ household }) => (
    <USDCell
      value={household.householdKPI?.AssetsLiabilities}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.extAccountAmount]: ({ household }) => (
    <USDCell
      value={household.householdKPI?.extAccountAmount}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.NNM_MTDNet]: ({ household }) => (
    <USDCell value={household?.householdKPI?.MTDNet} fractionDigits={0} />
  ),
  [HouseholdColumnIds.NNM_MTDInflow]: ({ household }) => (
    <USDCell value={household?.householdKPI?.MTDInflow} fractionDigits={0} />
  ),
  [HouseholdColumnIds.NNM_MTDOutflow]: ({ household }) => (
    <USDCell value={household?.householdKPI?.MTDOutflow} fractionDigits={0} />
  ),
  [HouseholdColumnIds.NNM_QTDNet]: ({ household }) => (
    <USDCell value={household?.householdKPI?.QTDNet} fractionDigits={0} />
  ),
  [HouseholdColumnIds.NNM_QTDInflow]: ({ household }) => (
    <USDCell value={household?.householdKPI?.QTDInflow} fractionDigits={0} />
  ),
  [HouseholdColumnIds.NNM_QTDOutflow]: ({ household }) => (
    <USDCell value={household?.householdKPI?.QTDOutflow} fractionDigits={0} />
  ),
  [HouseholdColumnIds.NNM_YTDNet]: ({ household }) => (
    <USDCell value={household?.householdKPI?.YTDNet} fractionDigits={0} />
  ),
  [HouseholdColumnIds.NNM_YTDInflow]: ({ household }) => (
    <USDCell value={household?.householdKPI?.YTDInflow} fractionDigits={0} />
  ),
  [HouseholdColumnIds.NNM_YTDOutflow]: ({ household }) => (
    <USDCell value={household?.householdKPI?.YTDOutflow} fractionDigits={0} />
  ),
  [HouseholdColumnIds.NNM_PriorDayNet]: ({ household }) => (
    <USDCell value={household?.householdKPI?.PriorDayNet} fractionDigits={0} />
  ),
  [HouseholdColumnIds.NNM_PriorDayInflow]: ({ household }) => (
    <USDCell
      value={household?.householdKPI?.PriorDayInflow}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.NNM_PriorDayOutflow]: ({ household }) => (
    <USDCell
      value={household?.householdKPI?.PriorDayOutflow}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.NNM_PriorYearNet]: ({ household }) => (
    <USDCell value={household?.householdKPI?.PriorYearNet} fractionDigits={0} />
  ),
  [HouseholdColumnIds.NNM_PriorYearInflow]: ({ household }) => (
    <USDCell
      value={household?.householdKPI?.PriorYearInflow}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.NNM_PriorYearOutflow]: ({ household }) => (
    <USDCell
      value={household?.householdKPI?.PriorYearOutflow}
      fractionDigits={0}
    />
  )
}

export const HouseholdCell = createHouseholdCell(HouseholdCellComponents)
