import { css } from '@emotion/react'

export const PrintDisclaimer: React.FC = () => {
  const classes = {
    disclaimer: css({
      fontSize: 14
    })
  }
  return (
    <>
      <h2>Disclosures</h2>
      <section css={classes.disclaimer}>
        <p>Note: Priced Investments Includes Cash Equivalents</p>
      </section>
    </>
  )
}
