import React, { useMemo } from 'react'
import { IBalanceDetailResponseValueItem } from '../../../store/balancesApi/IBalanceDetailResponse'
import { ExpandAllRows } from '../ExpandAllRows'
import { AccountDetails } from './AccountDetails'
import { CashModalTable } from './CashModalTable'
import { getModalData } from './getModalData'
import { useReactTableProps } from './useReactTableProps'

export const CashModalContent: React.FC<{
  rowData?: IBalanceDetailResponseValueItem
  accountKey?: string
  isAccountPrimary?: boolean
}> = ({ rowData, accountKey, isAccountPrimary }) => {
  const { key: accountIdOrKey, ismultimargin } = rowData || {}
  const accountIdOrKeySelected = useMemo(
    () => accountKey ?? accountIdOrKey,
    [accountIdOrKey, accountKey]
  )
  const modalData = useMemo(
    () => getModalData(rowData, accountKey),
    [rowData, accountKey]
  )

  const {
    canSomeRowsExpand,
    getToggleAllRowsExpandedHandler,
    headers,
    isAllRowsExpanded,
    rows,
    showNoData
  } = useReactTableProps(modalData)

  const expandAllRowsProps = useMemo(
    () => ({
      canSomeRowsExpand,
      onClick: getToggleAllRowsExpandedHandler,
      isAllRowsExpanded,
      showNoData
    }),
    [
      canSomeRowsExpand,
      getToggleAllRowsExpandedHandler,
      isAllRowsExpanded,
      showNoData
    ]
  )

  const accountDetailsProps = useMemo(
    () => ({
      accountIdOrKey: accountIdOrKeySelected ?? '',
      isMultiMargin: ismultimargin,
      isPrimary: ismultimargin ? isAccountPrimary : undefined
    }),
    [accountIdOrKeySelected, isAccountPrimary, ismultimargin]
  )

  return (
    <div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <ExpandAllRows {...expandAllRowsProps} />
        {accountIdOrKeySelected && <AccountDetails {...accountDetailsProps} />}
      </div>
      <CashModalTable headers={headers} rows={rows} showNoData={showNoData} />
    </div>
  )
}
