import { css } from '@emotion/react'
import { constants } from 'modules/Advisory/modules/Rdot360/shared/theme'
import { theme } from 'shared/theme'
import { textAlignCenter } from './styles'

export const getTableStyles = () => ({
  tableBase: css({
    tableLayout: 'fixed',
    width: '100%',
    boxSizing: 'border-box',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    marginBottom: 4,
    'th, td': {
      fontSize: 13,
      padding: 10,
      '&.displayCell': {
        padding: 0,
        verticalAlign: 'middle'
      },
      '&.selectedAccountKey': {
        color: theme.colors.secondaryYellow2,
        fontSize: theme.size.xl,
        padding: 0,
        verticalAlign: 'middle'
      }
    }
  }),
  tableHeader: css({
    backgroundColor: '#EBEBEB',
    minWidth: 1400,
    position: 'sticky',
    top: constants.blueBarHeight + constants.headerHeight,
    th: {
      verticalAlign: 'top',
      paddingTop: 10,
      paddingBottom: 10,
      textAlign: 'right',
      '&.centered': [
        textAlignCenter,
        {
          backgroundColor: '#E1E7EC',
          paddingTop: 4,
          paddingBottom: 4
        }
      ]
    }
  }),
  tableContent: css({
    tbody: {
      border: '1px solid #E6E6E6',
      tr: {
        ':first-of-type': {
          backgroundColor: '#EBF6FC'
        },
        '&.headerRow': {
          borderTop: '1px solid #E6E6E6'
        }
      },
      td: {
        color: '#080808',
        verticalAlign: 'top',
        textAlign: 'right'
      }
    }
  }),
  tableFooter: css({
    backgroundColor: '#cde2f2',
    position: 'sticky',
    bottom: 0,
    td: {
      fontWeight: 'bold',
      textAlign: 'right',
      paddingTop: 10,
      paddingBottom: 10
    }
  })
})
