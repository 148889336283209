import { IClient } from 'api/client.types'
import { INfsProfile } from 'api/datahub'
import { CDMv2Account } from 'api/nickname.types'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { datahubApi } from 'store/api/datahub'

export const activateProfileDatahubApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    checkForProfile: builder.query<INfsProfile | undefined, string>({
      query: (partyId) => ({
        url: `/nfsProfiles`,
        params: {
          $filter: `partyid eq '${partyId}'`,
          $top: 1
        }
      }),
      transformResponse: (x: IOdataResult<INfsProfile>) => x?.value?.[0]
    }),
    getAccountsForParty: builder.query<CDMv2Account[] | undefined, string>({
      query: (partyId) => ({
        url: `/accounts`,
        params: {
          $filter: `stakeholders/any(s: s/partyID eq '${partyId}')`,
          $expand:
            'stakeholders($expand=party,stakeholderRelationship,accountRelationship,stakeholderType), household'
        }
      }),
      transformResponse: (x: IOdataResult<CDMv2Account>) => x?.value
    }),
    getClientDetails: builder.query<IClient | undefined, string>({
      query: (partyId) => ({
        url: 'search/clients',
        params: {
          $filter: `LegalEntityID eq '${partyId}'`,
          $select: 'contactdetails,ClientAdvisorID'
        }
      }),
      transformResponse: (x: IOdataResult<IClient>) => x?.value?.[0]
    }),
    checkIfEmailInUse: builder.query<boolean, string | undefined>({
      query: (email) => ({
        url: '/nfsProfiles/CheckIfEmailInUse',
        method: 'POST',
        data: { email }
      }),
      transformResponse: (x: { value: boolean }) => !!x?.value
    })
  })
})

export const {
  useCheckForProfileQuery,
  useGetAccountsForPartyQuery,
  useGetClientDetailsQuery,
  useLazyCheckIfEmailInUseQuery
} = activateProfileDatahubApi
