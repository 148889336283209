export enum CommonColumnIdsEnum {
  sectorAllocation = 'sectorAllocation',
  productAllocation = 'productAllocation',
  assetAllocation = 'assetAllocation',
  purchasingPower = 'purchasingPower',
  aus = 'aus',
  loansOutstanding = 'loansOutstanding',
  brokerageAssets = 'brokerageAssets',
  aum = 'aum',
  annuity = 'annuity',
  legalEntityName = 'legalEntityName',
  positions = 'positions',
  team = 'team',
  clientAdvisor = 'clientAdvisor',
  clientAdvisorId = 'clientAdvisorId',
  householdId = 'householdId',
  householdName = 'householdName',
  NNM_MTDNet = 'NNM_MTDNet',
  NNM_MTDInflow = 'NNM_MTDInflow',
  NNM_MTDOutflow = 'NNM_MTDOutflow',
  NNM_QTDNet = 'NNM_QTDNet',
  NNM_QTDInflow = 'NNM_QTDInflow',
  NNM_QTDOutflow = 'NNM_QTDOutflow',
  NNM_YTDNet = 'NNM_YTDNet',
  NNM_YTDInflow = 'NNM_YTDInflow',
  NNM_YTDOutflow = 'NNM_YTDOutflow',
  NNM_PriorDayNet = 'NNM_PriorDayNet',
  NNM_PriorDayInflow = 'NNM_PriorDayInflow',
  NNM_PriorDayOutflow = 'NNM_PriorDayOutflow',
  NNM_PriorYearNet = 'NNM_PriorYearNet',
  NNM_PriorYearInflow = 'NNM_PriorYearInflow',
  NNM_PriorYearOutflow = 'NNM_PriorYearOutflow'
}
