import { ProgressIndicator } from '@fluentui/react'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { ExpandAllRows } from '../../../features/Loans/ExpandAllRows'
import { ThirdPartyLoansTable } from './ThirdPartyLoansTable'
import { useReactTableProps } from './useReactTableProps'

export const ThirdPartyLoans: React.FC = () => {
  const {
    canSomeRowsExpand,
    getToggleAllRowsExpandedHandler,
    headers,
    isAllRowsExpanded,
    isFetching,
    rows,
    showNoData,
    error
  } = useReactTableProps()

  const expandAllRowsProps = {
    canSomeRowsExpand,
    onClick: getToggleAllRowsExpandedHandler,
    isAllRowsExpanded,
    isFetching,
    showNoData
  }

  return (
    <>
      <ExpandAllRows {...expandAllRowsProps} />
      <HorizontalScrollContainer>
        <div css={{ height: 2 }}>
          <ProgressIndicator
            progressHidden={!isFetching}
            styles={{
              itemProgress: { margin: 0, padding: 0 }
            }}
          />
        </div>
        <ThirdPartyLoansTable
          headers={headers}
          rows={rows}
          showNoData={showNoData}
          error={error as Error}
        />
        <div css={{ marginTop: 10, fontSize: 10 }}>
          Note: This report includes all third-party loans for the selected
          client and is not driven by the accounts selected within the Account
          Selector
        </div>
      </HorizontalScrollContainer>
    </>
  )
}
