import { css } from '@emotion/react'

interface IPrintHeaderSpacer {
  spaceAbove: number
  spaceBelow: number
}

export const PrintHorizontalRule: React.FC<IPrintHeaderSpacer> = ({
  spaceAbove,
  spaceBelow
}) => {
  const classes = {
    space: css({
      marginTop: spaceAbove,
      marginBottom: spaceBelow
    }),
    line: css({
      borderTop: '1px solid #AFB1B6'
    })
  }

  return <div css={[classes.space, classes.line]} />
}
