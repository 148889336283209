import { css } from '@emotion/react'
import { flexRender, Header, Row } from '@tanstack/react-table'
import { IBalanceDetailResponseValueItem } from 'modules/Advisory/modules/Rdot360/store/balancesApi/IBalanceDetailResponse'
import { FC } from 'react'
import { SnackBar } from 'shared/uiSharedComponents/Snackbar'
import { getMLBorderStyles } from '../../../features/Loans/getBorderStyles'
import { getTableStyles } from '../../../features/Loans/getTableStyles'
import { TableCell } from '../../../features/Loans/TableCellsGeneric'

const classes = getTableStyles()

interface IHeadingProp {
  headers: Header<IBalanceDetailResponseValueItem, unknown>[]
}

const THead: FC<IHeadingProp> = ({ headers }) => (
  <table css={[classes.tableBase, classes.tableHeader]}>
    <thead>
      <tr>
        <th css={{ width: 20 }} />
        <th colSpan={7} />
        <th css={getMLBorderStyles('colSpanHeader')} />
        <th
          colSpan={3}
          className="centered"
          css={getMLBorderStyles('colSpanHeader')}
        >
          Available to Withdraw
        </th>
        <th
          colSpan={2}
          className="centered"
          css={getMLBorderStyles('colSpanHeader')}
        >
          Available to Invest
        </th>
        <th colSpan={2} css={getMLBorderStyles('colSpanHeader')} />
      </tr>
      <tr>
        {headers?.map((header, i) => (
          <th
            key={i}
            css={[
              { width: header.column.columnDef.size },
              getMLBorderStyles(header.column.id)
            ]}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        ))}
      </tr>
    </thead>
  </table>
)

interface IBodyProp {
  rows: Row<IBalanceDetailResponseValueItem>[]
}

const TBody: FC<IBodyProp> = ({ rows }) =>
  rows.map((row) => {
    const visibleCells = row.getVisibleCells()
    const isExpanded = row.getIsExpanded()
    const rowDepth = row.depth

    return rowDepth !== 0 ? null : (
      <table key={row.id} css={[classes.tableBase, classes.tableContent]}>
        <tbody>
          <tr>
            {visibleCells.map((cell) => (
              <TableCell
                key={cell.id}
                tableCell={cell}
                styles={getMLBorderStyles(cell.column.id, '#E6E6E6')}
                className={cell.column.columnDef.meta?.className}
              />
            ))}
          </tr>

          {isExpanded &&
            row.subRows.map((subRow, i) => {
              const subVisibleCells = subRow.getVisibleCells()
              const isEven = i % 2 === 0
              const subRowStyles = css({
                backgroundColor: isEven ? '#FFFFFF' : '#FAFAFA'
              })

              return (
                <tr key={subRow.id} className="headerRow" css={subRowStyles}>
                  {subVisibleCells.map((cell) => (
                    <TableCell
                      key={cell.id}
                      tableCell={cell}
                      styles={getMLBorderStyles(cell.column.id, '#E6E6E6')}
                    />
                  ))}
                </tr>
              )
            })}
        </tbody>
      </table>
    )
  })

export const MarginLoansTable: FC<
  IHeadingProp & IBodyProp & { showNoData?: boolean; error?: Error }
> = ({ headers, rows, showNoData, error }) => (
  <>
    <THead headers={headers} />
    {error || showNoData ? (
      <div
        css={{
          paddingTop: 10,
          paddingBottom: 20
        }}
      >
        {error && (
          <SnackBar
            type="Failure"
            message={error.message || 'An unknown error occurred'}
          />
        )}
        {showNoData && <SnackBar type="Info" message="No data available" />}
      </div>
    ) : (
      <>
        <TBody rows={rows} />
      </>
    )}
  </>
)
