import { css } from '@emotion/react'
import { sortBy } from 'lodash'
import { FC } from 'react'
import { theme } from 'shared/theme'
import { IForeignCurrency } from '../../../store/balancesApi/IBalanceDetailResponse'
import { Currency, USD } from '../formatting'
import { textAlignLeft } from '../styles'

const subHeaderBorderStyle = css({
  border: `1px solid ${theme.colors.tertiaryGray4}` // #D1D3D3
})

const CurrencySubHeaderCell: FC<{ title?: string }> = ({ title }) => {
  return (
    <>
      <div>{title}</div>
      <div
        css={{
          marginTop: 10,
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          color: theme.colors.tertiaryGray2,
          fontSize: 12
        }}
      >
        <div css={{ flex: '0 0 50%' }}>Local Currency</div>
        <div>USD Equivalent</div>
      </div>
    </>
  )
}

const CurrencyDetails: FC<{
  currencycode?: string
  localValue?: number
  usdValue?: number
}> = ({ currencycode, localValue, usdValue }) => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      <div css={{ color: theme.colors.tertiaryGray2, flex: '0 0 50%' }}>
        <Currency value={localValue} currency={currencycode} />
      </div>
      <div>
        <USD value={usdValue} />
      </div>
    </div>
  )
}

export const ForeignCurrencyDetails: FC<{
  foreignCurrency?: IForeignCurrency[]
}> = ({ foreignCurrency = [] }) => {
  const foreignCurrencySorted = sortBy(
    foreignCurrency,
    ({ currencycode }) => currencycode
  )

  return (
    <>
      <tr
        css={{
          backgroundColor: '#EBEBEB',
          border: `1px solid ${theme.colors.tertiaryGray4}` // #E6E6E6
        }}
      >
        <td />
        <td>
          <div css={textAlignLeft}>Foreign Currency</div>
        </td>
        <td>
          <CurrencySubHeaderCell title="Settelment Date Balance" />
        </td>
        <td css={subHeaderBorderStyle}>
          <CurrencySubHeaderCell title="Pending Activity" />
        </td>
        <td>
          <CurrencySubHeaderCell title="Trade Date Balance" />
        </td>
      </tr>
      {foreignCurrencySorted.map((currencyItem, index) => {
        const isEven = index % 2 === 0
        const subRowStyles = css({
          backgroundColor: isEven ? '#FFFFFF' : '#FAFAFA'
        })
        const {
          currencycode,
          sdlocalvalue = 0,
          sdusdequivalent = 0,
          localvalue = 0,
          usdequivalent = 0
        } = currencyItem

        const pendingLocalValue = localvalue - sdlocalvalue
        const pendingUsdValue = usdequivalent - sdusdequivalent

        return (
          <tr key={`${currencycode}-${index}`} css={subRowStyles}>
            <td />
            <td>
              <div css={textAlignLeft}>{currencycode}</div>
            </td>
            <td>
              <CurrencyDetails
                currencycode={currencycode}
                localValue={sdlocalvalue}
                usdValue={sdusdequivalent}
              />
            </td>
            <td>
              <CurrencyDetails
                currencycode={currencycode}
                localValue={pendingLocalValue}
                usdValue={pendingUsdValue}
              />
            </td>
            <td>
              <CurrencyDetails
                currencycode={currencycode}
                localValue={localvalue}
                usdValue={usdequivalent}
              />
            </td>
          </tr>
        )
      })}
    </>
  )
}
