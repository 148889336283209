import { Icon, TooltipHost } from '@fluentui/react'
import {
  IEmoneyAccountConnection,
  useGetEmoneyAccountConnections
} from './useGetEmoneyAccountConnections'

const getStatus = (
  emoneyAccountConnections: IEmoneyAccountConnection[],
  accountKey: string
) => {
  for (const connection of emoneyAccountConnections) {
    if (connection.accountKeys) {
      for (const key of connection.accountKeys) {
        if (key.trim() === accountKey) {
          return connection.status
        }
      }
    }
  }
  return ''
}

export const StaleDataIndicator: React.FC<{
  accountKey: string | undefined
}> = ({ accountKey }) => {
  const emoneyAccountConnections = useGetEmoneyAccountConnections()
  const status = getStatus(
    emoneyAccountConnections || [],
    accountKey || ''
  ).toUpperCase()
  const isStatusOK = ['', 'UP-TO-DATE', 'UPTODATE', 'PENDING'].includes(status)
  if (isStatusOK) {
    return null
  }
  const tooltipContent =
    'The data for this account is not current. For assistance, please use the Report an Issue feature in Help and Support'
  return (
    <span
      css={{
        color: '#8B0000',
        paddingLeft: 5
      }}
    >
      <TooltipHost content={tooltipContent}>
        <Icon iconName="info" styles={{ root: { cursor: 'pointer' } }} />
      </TooltipHost>
    </span>
  )
}
