import { createColumnHelper } from '@tanstack/react-table'
import { IBalanceDetailResponseValueItem } from 'modules/Advisory/modules/Rdot360/store/balancesApi/IBalanceDetailResponse'
import { textAlignLeft } from '../../../features/Loans/styles'
import {
  ColumnHeaderWithSortIndicator,
  DisplayCell,
  ISODateCell,
  ISODateCellAggregated,
  PercentageCell,
  PledgedAccountsCellAggregated,
  USDCellTotalAmount,
  USDCell,
  AccountCellExtended,
  HighlightedSearchCell,
  ServiceProviderCell
} from '../../../features/Loans/TableCellsGeneric'

const columnHelper = createColumnHelper<IBalanceDetailResponseValueItem>()

export const getColumnDefinitions = () => [
  columnHelper.display({
    id: 'display',
    aggregatedCell: DisplayCell,
    cell: DisplayCell,
    size: 20,
    meta: { className: 'displayCell' }
  }),
  columnHelper.accessor(({ key }) => key, {
    id: 'accountKey',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props} leftAligned>
        Account
      </ColumnHeaderWithSortIndicator>
    ),
    cell: AccountCellExtended,
    footer: () => <div css={textAlignLeft}>Totals</div>
  }),
  columnHelper.accessor(({ key }) => key, {
    id: 'serviceProvider',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props} leftAligned>
        Service Provider
      </ColumnHeaderWithSortIndicator>
    ),
    cell: () => <div css={textAlignLeft}>--</div>,
    aggregationFn: 'max',
    aggregatedCell: ServiceProviderCell,
    size: 100
  }),
  columnHelper.accessor(({ customername }) => customername, {
    id: 'customerName',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props} leftAligned>
        Borrower&apos;s Name
      </ColumnHeaderWithSortIndicator>
    ),
    cell: () => <div css={textAlignLeft}>--</div>,
    aggregationFn: 'max',
    aggregatedCell: (props) => <HighlightedSearchCell {...props} leftAligned />
  }),
  columnHelper.accessor(({ collateralaccounts }) => collateralaccounts, {
    id: 'collateralAccounts',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props} leftAligned>
        Pledged Accounts
      </ColumnHeaderWithSortIndicator>
    ),
    cell: () => <div css={textAlignLeft}>--</div>,
    aggregationFn: 'unique',
    aggregatedCell: PledgedAccountsCellAggregated
  }),
  columnHelper.accessor(({ totalcredit }) => totalcredit, {
    id: 'totalCredit',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Approved Loan Amount
      </ColumnHeaderWithSortIndicator>
    ),
    cell: () => '--',
    aggregationFn: 'max',
    aggregatedCell: USDCell
  }),
  columnHelper.accessor(({ totalaccountvalue }) => totalaccountvalue, {
    id: 'totalAccountvalue',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Loan Balance
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregationFn: 'sum',
    aggregatedCell: USDCell,
    footer: (props) => <USDCellTotalAmount {...props} inColor />
  }),
  columnHelper.accessor(({ availablecredit }) => availablecredit, {
    id: 'availableCredit',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Available to Borrow
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregationFn: 'max',
    aggregatedCell: USDCell,
    footer: USDCellTotalAmount
  }),
  columnHelper.accessor(({ loanopendate }) => loanopendate, {
    id: 'loanOpenDate',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Origination Date
      </ColumnHeaderWithSortIndicator>
    ),
    cell: ISODateCell,
    aggregationFn: 'unique',
    aggregatedCell: ISODateCellAggregated,
    size: 110
  }),
  columnHelper.accessor(() => null, {
    // ToDo: Not available yet
    id: 'maturityDate',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Maturity Date
      </ColumnHeaderWithSortIndicator>
    ),
    cell: ISODateCell,
    aggregationFn: 'unique',
    aggregatedCell: ISODateCellAggregated,
    size: 110
  }),
  columnHelper.accessor(({ rateindex }) => rateindex, {
    id: 'loanType',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Loan Type
      </ColumnHeaderWithSortIndicator>
    ),
    cell: HighlightedSearchCell,
    aggregationFn: 'max',
    aggregatedCell: ({ row, getValue }) => {
      const { subRows } = row
      if (subRows.length > 1) {
        return <>--</>
      }

      return <HighlightedSearchCell getValue={getValue} />
    },
    size: 180
  }),
  columnHelper.accessor(({ interestrate }) => interestrate, {
    id: 'interestRate',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Interest Rate
      </ColumnHeaderWithSortIndicator>
    ),
    cell: PercentageCell,
    aggregationFn: 'max',
    aggregatedCell: (props) => {
      const { row } = props
      if (row.subRows.length > 1) {
        return <>--</>
      }

      return <PercentageCell {...props} />
    },
    size: 100
  })
]
