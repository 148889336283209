import { FC, useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import { useMasking } from 'store/shared/hooks/useMaskedText'
import { mask } from '../../../shared/utilities'
import { useRdot360AccountContext } from '../../../store/rdot360Context'

export const AccountDetails: FC<{
  accountIdOrKey: string
  isMultiMargin?: boolean
  isPrimary?: boolean
}> = ({ accountIdOrKey, isMultiMargin, isPrimary }) => {
  const { mask: coverUp, shouldMask } = useMasking()
  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()
  const accountInfo = useMemo(
    () => accountLookupByAccountIdOrKey[accountIdOrKey],
    [accountIdOrKey, accountLookupByAccountIdOrKey]
  )
  const {
    AccountNickname,
    registrationtype,
    LegalEntityName,
    CustodyAccount: accountNumberFromLookup,
    RDOTAccountCategoryCode
  } = accountInfo || {}
  const isExternal = RDOTAccountCategoryCode === '03'

  const accountDetails = useMemo(
    () => [
      coverUp(AccountNickname, 'nickname'),
      isExternal || shouldMask
        ? mask(accountNumberFromLookup)
        : accountNumberFromLookup,
      registrationtype,
      isMultiMargin ? 'Multi Margin' : 'Single Margin',
      isPrimary == null ? null : isPrimary ? 'Primary' : 'Secondary',
      coverUp(LegalEntityName, 'name')
    ],
    [
      AccountNickname,
      LegalEntityName,
      accountNumberFromLookup,
      coverUp,
      isExternal,
      isMultiMargin,
      isPrimary,
      registrationtype,
      shouldMask
    ]
  )

  return <div>{accountDetails.filter(isNotNullOrUndefined).join(' | ')}</div>
}
