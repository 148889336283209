import { Pivot, PivotItem } from '@fluentui/react'
import { useCallback, useMemo } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { NotFound } from 'shared/components/NotFound'
import { useRouteMatch } from 'shared/hooks/useRouteMatch'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import { CollaborationContainer } from '../Collaboration/CollaborationContainer'
import { SecureMessageContainer } from './modules/SecureMessages/SecureMessageContainer'
import { useSecureMessagesCount } from './modules/SecureMessages/shared/hooks'

export const CommunicationsContainer: React.FC = () => {
  const navigate = useNavigate()
  const { firstSubSegment } = useRouteMatch()
  const { unread } = useSecureMessagesCount()

  const tabs = useMemo(
    () => [
      {
        name: 'Collaboration',
        key: 'collaboration'
      },
      {
        name: `Secure Message${unread ? ` (${unread})` : ''}`,
        key: 'securemessage'
      }
    ],
    [unread]
  )

  const onSelectedTabChanged = useCallback(
    (item?: PivotItem) => {
      const newSelectedTab = item?.props?.itemKey
      if (!newSelectedTab) {
        return
      }
      navigate(newSelectedTab)
    },
    [navigate]
  )

  return (
    <div>
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          gap: 20
        }}
      >
        <DetailsNavigator />
        <Pivot selectedKey={firstSubSegment} onLinkClick={onSelectedTabChanged}>
          {tabs.map(({ name, key }) => (
            <PivotItem key={key} headerText={name} itemKey={key} />
          ))}
        </Pivot>
      </div>
      <Routes>
        <Route path="/" element={<Navigate to="collaboration" replace />} />
        <Route
          path="/collaboration"
          element={<CollaborationContainer hideDetailsNavigator={true} />}
        />
        <Route path="/secureMessage" element={<SecureMessageContainer />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  )
}
