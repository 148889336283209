import { IconButton, Modal } from '@fluentui/react'
import { buttonStyles } from 'modules/Advisory/modules/Rdot360/components/shared/Buttons'

export interface IConfirmModalProps {
  message: string
  onDismiss: () => void
  onPrimaryClick: () => void
  onSeconardyClick: () => void
  primaryText: string
  secondaryText: string
  width: number
}

export const ConfirmModal: React.FC<IConfirmModalProps> = ({
  message,
  onDismiss,
  onPrimaryClick,
  onSeconardyClick,
  primaryText,
  secondaryText,
  width
}) => {
  return (
    <Modal
      isOpen={true}
      onDismiss={onDismiss}
      styles={{ main: { display: 'flex' } }}
      isBlocking={true}
    >
      <div css={{ width }}>
        <div
          css={{
            padding: '16px 0px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close"
            onClick={onDismiss}
            css={{
              marginRight: 20,
              height: 22,
              ':hover': {
                backgroundColor: 'transparent'
              }
            }}
          />
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 30,
            fontWeight: 500,
            fontSize: 18,
            padding: '0 16px',
            textAlign: 'center'
          }}
        >
          {message}
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            padding: 16
          }}
        >
          <button
            css={[buttonStyles.secondary, { width: 100 }]}
            onClick={onSeconardyClick}
          >
            {secondaryText}
          </button>
          <button
            css={[buttonStyles.primary, { marginLeft: 16, width: 100 }]}
            onClick={onPrimaryClick}
          >
            {primaryText}
          </button>
        </div>
      </div>
    </Modal>
  )
}
