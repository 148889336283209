import { ProgressIndicator } from '@fluentui/react'
import { useMemo } from 'react'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { ExpandAllRows } from '../../../features/Loans/ExpandAllRows'
import { MarginLoansTable } from './MarginLoansTable'
import { useReactTableProps } from './useReactTableProps'

export const MarginLoans: React.FC = () => {
  const {
    canSomeRowsExpand,
    getToggleAllRowsExpandedHandler,
    headers,
    isAllRowsExpanded,
    isFetching,
    rows,
    showNoData,
    error
  } = useReactTableProps()

  const expandAllRowsProps = useMemo(
    () => ({
      canSomeRowsExpand,
      onClick: getToggleAllRowsExpandedHandler,
      isAllRowsExpanded,
      isFetching,
      showNoData
    }),
    [
      canSomeRowsExpand,
      getToggleAllRowsExpandedHandler,
      isAllRowsExpanded,
      isFetching,
      showNoData
    ]
  )

  return (
    <>
      <ExpandAllRows {...expandAllRowsProps} />
      <HorizontalScrollContainer>
        <div css={{ height: 2 }}>
          <ProgressIndicator
            progressHidden={!isFetching}
            styles={{
              itemProgress: { margin: 0, padding: 0 }
            }}
          />
        </div>
        <MarginLoansTable
          headers={headers}
          rows={rows}
          showNoData={showNoData}
          error={error as Error}
        />
        <div css={{ marginTop: 10, fontSize: 10 }}>
          Note: This report includes all margin loans for the selected client
          and is not driven by the accounts selected within the Account Selector
        </div>
      </HorizontalScrollContainer>
    </>
  )
}
