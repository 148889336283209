import { Interpolation, Theme } from '@emotion/react'
import {
  ColumnDef,
  FilterFnOption,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  RowData,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import { detailTable } from 'modules/Advisory/modules/Rdot360/components/shared/DataTable/DetailTable'
import { constants } from 'modules/Advisory/modules/Rdot360/shared/theme'
import { useState } from 'react'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { useSecureMessagesState } from '../../shared/hooks'

export interface ISecureMessagesTableProps<T extends RowData> {
  columns: ColumnDef<T, any>[]
  data: T[]
  getRowCss?: (row: Row<T>) => Interpolation<Theme>
  getRowId: (originalRow: T, index: number, parent?: Row<T>) => string
  globalFilter: any
  globalFilterFn: FilterFnOption<T>
}

export const SecureMessagesTable = <T,>({
  columns,
  data,
  getRowCss,
  getRowId,
  globalFilter,
  globalFilterFn
}: ISecureMessagesTableProps<T>) => {
  const { rowSelection, setRowSelection } = useSecureMessagesState()

  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    globalFilterFn,
    state: {
      globalFilter,
      sorting,
      rowSelection
    },
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId
  })

  return (
    <HorizontalScrollContainer>
      <table css={[detailTable.detailTable, { minWidth: '950px' }]}>
        <thead>
          <tr
            css={{
              position: 'sticky',
              top: constants.headerOffsetPx,
              zIndex: 1
            }}
          >
            {table.getFlatHeaders().map((header) => {
              return (
                <th
                  key={header.id}
                  style={{
                    width: header.getSize(),
                    maxWidth: header.column.columnDef.maxSize,
                    minWidth: header.column.columnDef.minSize
                  }}
                >
                  <div
                    onClick={header.column.getToggleSortingHandler()}
                    css={{
                      cursor: 'pointer',
                      height: '16px'
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} css={getRowCss?.(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} style={{ height: '50px' }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </HorizontalScrollContainer>
  )
}
