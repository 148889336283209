import { FC } from 'react'
import { USDCellWithColor } from '../../../components/USDCellWithColor'
import { USD } from '../formatting'

export const ListItem: FC<{
  label?: string
  value?: number
  colored?: true
  shaded?: true
  spotlit?: true
}> = ({ label, value, colored, shaded, spotlit }) => {
  const bgColor = spotlit ? '#E4EFF7' : shaded ? '#F6F6F6' : undefined
  const fontWeight = spotlit ? 500 : undefined
  return (
    <div
      css={{
        display: 'flex',
        gap: 10,
        justifyContent: 'space-between',
        padding: 4,
        backgroundColor: bgColor,
        fontWeight,
        marginBottom: 4
      }}
    >
      <div>{label}</div>
      <div>
        {colored ? <USDCellWithColor value={value} /> : <USD value={value} />}
      </div>
    </div>
  )
}
