import { css } from '@emotion/react'

export const PrintDisclaimer: React.FC = () => {
  const classes = {
    disclaimer: css({
      fontSize: 14
    })
  }
  return (
    <>
      <h2>Disclosures</h2>
      <section css={classes.disclaimer}>
        <p>
          Note: Rockefeller Accounts Only. The information herein is for
          informational purposes only. For tax reporting or calculations, you
          should only rely on your annual Tax Reporting Statement consisting of
          various Forms of 1099 (or any other appropriate tax form). Rockefeller
          Financial LLC cannot guarantee that the information herein is
          accurate, complete, or timely. Rockefeller Financial LLC does not
          provide legal or tax advice. You should consult with a tax or legal
          professional to address your particular situation. Taxable Accounts is
          based on the account data and not individual investments within the
          accounts. External accounts or those from other custodians may not be
          identifiable as Taxable and not displayed.
        </p>
      </section>
    </>
  )
}
