import { skipToken } from '@reduxjs/toolkit/query'
import { useCallback, useMemo } from 'react'
import {
  useGetMailFoldersQuery,
  useGetUnreadMessagesWithClientQuery
} from '../store'
import { secureMessageFolders } from '../types'
import { useSecureMessagesMailbox } from './useSecureMessagesMailbox'

export const useSecureMessagesCount = () => {
  const { mailboxPrincipalNames } = useSecureMessagesMailbox()
  const search = useMemo(
    () => mailboxPrincipalNames.map((x) => `participants:${x}`).join(' OR '),
    [mailboxPrincipalNames]
  )

  const { data: mailFolders } = useGetMailFoldersQuery()
  const { data: messages } = useGetUnreadMessagesWithClientQuery(
    search.length ? search : skipToken
  )

  const mailFoldersMap = useMemo(() => {
    const map = new Map<string, number | undefined>()
    mailFolders?.forEach((folder) => {
      const messagesInFolder = messages?.filter(
        (message) => message.parentFolderId === folder.id
      )
      const messagesInFolderLength =
        messagesInFolder && messagesInFolder.length > 0
          ? messagesInFolder.length
          : undefined
      map.set(folder.displayName, messagesInFolderLength)
    })
    return map
  }, [mailFolders, messages])

  const getFolderCount = useCallback(
    (displayName: string) => mailFoldersMap.get(displayName),
    [mailFoldersMap]
  )

  const unread = useMemo(() => {
    let sum = 0
    for (const folder of secureMessageFolders) {
      const value = getFolderCount(folder.name)
      if (value) {
        sum += value
      }
    }

    return sum
  }, [getFolderCount])

  return { getFolderCount, unread }
}
