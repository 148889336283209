import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import { getColumnDefinitions } from './ColumnDefinitions'
import type { ICashBalanceDetails } from './getModalData'

export const meetsForeignCurrencyCondition = (
  originalRow: ICashBalanceDetails
) => {
  const { foreignCurrency = [], cashBalanceType } = originalRow
  return (
    cashBalanceType === 'Foreign Currency (USDE)' && foreignCurrency.length > 0
  )
}

const getExpandedRows = (arr: number[]) => {
  const expanded: { [key: number]: boolean } = {}
  for (const x of arr) {
    expanded[x] = true
  }
  return expanded
}

export const useReactTableProps = (tableData: ICashBalanceDetails[]) => {
  const showNoData = useMemo(() => !tableData.length, [tableData.length])
  const columns = useMemo(() => getColumnDefinitions(), [])
  const rowsWithSelectedKey = useMemo(
    () =>
      tableData
        .map((row, index) => {
          const isSomeKeySelected = row.multiMarginDetails?.some(
            (x) => x.isKeySelected
          )

          return isSomeKeySelected ? index : null
        })
        .filter(isNotNullOrUndefined),
    [tableData]
  )
  const [expanded, setExpanded] = useState<ExpandedState>(
    getExpandedRows(rowsWithSelectedKey)
  )
  const getSubRows = (originalRow: ICashBalanceDetails) => {
    return originalRow.multiMarginDetails || []
  }
  const getRowCanExpand = (row: Row<ICashBalanceDetails>) =>
    row.getLeafRows().length > 0 || meetsForeignCurrencyCondition(row.original)

  const tableOptions = useMemo(
    () => ({
      data: tableData,
      columns,
      state: {
        expanded: expanded
      },
      autoResetExpanded: false,
      groupedColumnMode: undefined,
      getRowCanExpand,
      onExpandedChange: setExpanded,
      getExpandedRowModel: getExpandedRowModel(),
      getGroupedRowModel: getGroupedRowModel(),
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getSubRows
    }),
    [tableData, columns, expanded]
  )

  const table = useReactTable(tableOptions)
  const { rows } = table.getRowModel()
  const canSomeRowsExpand = table.getCanSomeRowsExpand()
  const headers = table.getFlatHeaders()
  const { getToggleAllRowsExpandedHandler } = table
  const isAllRowsExpanded = table.getIsAllRowsExpanded()
  const isSomeRowsExpanded = table.getIsSomeRowsExpanded()

  return {
    canSomeRowsExpand,
    getToggleAllRowsExpandedHandler,
    headers,
    isAllRowsExpanded,
    isSomeRowsExpanded,
    rows,
    showNoData
  }
}
