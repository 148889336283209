import { convertColumnTypeToFilterType } from 'features/OdataList/common/service'
import { IOdataListColumnDefinition } from 'features/OdataList/common/types'
import { keyBy } from 'lodash'
import {
  IListsFilter,
  IListsFilterProps,
  IListsFilterPropTypes
} from '../core/contracts/IListsFilter'

export type ActivityColumnName =
  | 'Activity Date'
  | 'Account Number'
  | 'Advisor ID'
  | 'Amount'
  | 'Description'
  | 'Household Id'
  | 'Household Name'
  | 'Key Code'
  | 'Key Code Description'
  | 'Party Name'
  | 'Party Status'
  | 'Party Type'
  | 'Price'
  | 'Reference Number'
  | 'Quantity'
  | 'Symbol / CUSIP'
  | 'Team Name'
  | 'Transaction Type'
  | 'Transaction Type Description'
  | 'Type'

export interface IActivityColumnDefinition extends IOdataListColumnDefinition {
  name: ActivityColumnName
}

export const ActivityColumnDefinitions: IActivityColumnDefinition[] = [
  {
    name: 'Activity Date',
    dataPath: 'tradeDate',
    type: 'date-only',
    width: 140,
    removable: true,
    sortable: true,
    filterable: true,
    select: ['settleDate']
  },
  {
    name: 'Account Number',
    dataPath: 'accountNumber',
    filterable: true,
    removable: true,
    searchFields: ['accountNumber'],
    select: [
      'accountId',
      'accountCategoryCode',
      'accountNickname',
      'custodyAccount',
      'householdId',
      'registeredRep',
      'registrationTypeCode'
    ],
    sortable: true,
    type: 'string',
    width: 195
  },
  {
    name: 'Advisor ID',
    dataPath: 'registeredRep',
    type: 'string',
    width: 60,
    facetable: true,
    filterable: true,
    removable: true,
    sortable: true
  },
  {
    name: 'Amount',
    dataPath: 'marketValue',
    filterable: true,
    removable: true,
    sortable: true,
    type: 'number',
    width: 125
  },
  {
    name: 'Description',
    dataPath: 'tranDesc',
    filterable: true,
    type: 'string',
    width: 500,
    removable: true,
    sortable: true,
    searchFields: ['tranDesc']
  },
  {
    name: 'Household Id',
    dataPath: 'householdId',
    type: 'string',
    width: 120,
    removable: true,
    sortable: true
  },
  {
    name: 'Household Name',
    dataPath: 'householdName',
    filterable: true,
    removable: true,
    searchFields: ['householdName'],
    sortable: true,
    type: 'string',
    width: 220
  },
  {
    name: 'Key Code',
    dataPath: 'keyCode',
    facetable: true,
    filterable: true,
    removable: true,
    sortable: true,
    type: 'string',
    width: 80
  },
  {
    name: 'Key Code Description',
    dataPath: 'keyCodeDesc',
    filterable: true,
    removable: true,
    sortable: true,
    type: 'string',
    width: 220
  },
  {
    name: 'Party Name',
    dataPath: 'partyName',
    type: 'string',
    width: 180,
    removable: true,
    sortable: true
  },
  {
    name: 'Party Status',
    dataPath: 'partyStatus',
    type: 'string',
    width: 100,
    removable: true,
    sortable: true
  },
  {
    name: 'Party Type',
    dataPath: 'partyType',
    type: 'string',
    width: 50,
    removable: true,
    sortable: true
  },
  {
    name: 'Price',
    dataPath: 'price',
    type: 'number',
    width: 125,
    removable: true,
    sortable: true
  },
  {
    name: 'Quantity',
    dataPath: 'quantity',
    type: 'number',
    width: 125,
    removable: true,
    sortable: true
  },
  {
    name: 'Reference Number',
    dataPath: 'activityReferenceNo',
    filterable: true,
    removable: true,
    sortable: true,
    type: 'string',
    width: 100
  },
  {
    name: 'Symbol / CUSIP',
    dataPath: 'symbol',
    filterable: true,
    removable: true,
    searchFields: ['cusip', 'symbol'],
    select: ['cusip', 'symbol'],
    sortable: true,
    type: 'string',
    width: 140
  },
  {
    name: 'Team Name',
    dataPath: 'teamName',
    filterable: true,
    removable: true,
    sortable: true,
    type: 'string',
    width: 180
  },
  {
    name: 'Transaction Type',
    dataPath: 'transactionType',
    facetable: true,
    filterable: true,
    removable: true,
    sortable: true,
    type: 'string',
    width: 90
  },
  {
    name: 'Transaction Type Description',
    dataPath: 'transactionTypeDesc',
    filterable: true,
    removable: true,
    sortable: true,
    type: 'string',
    width: 220
  },
  {
    name: 'Type',
    dataPath: 'rcmCatDesc',
    type: 'string',
    width: 180,
    facetable: true,
    filterable: true,
    removable: true,
    sortable: true
  }
]

const hiddenFilters: ActivityColumnName[] = ['Advisor ID']

const createColumnFilter = (
  column: IActivityColumnDefinition
): IListsFilter => ({
  id: column.name,
  name: column.name,
  type: convertColumnTypeToFilterType(column),
  dataPath: column.dataPath,
  hasValue: false,
  hidden: hiddenFilters.includes(column.name)
})

export const createActivityFilter = (
  columnName: ActivityColumnName,
  filterProps: IListsFilterPropTypes & IListsFilterProps
): IListsFilter => {
  const column = ActivityColumnDefinitions.find((x) => x.name === columnName)
  if (!column) {
    return { id: columnName, name: columnName, ...filterProps }
  }

  return { ...createColumnFilter(column), ...filterProps }
}

export const ActivityFilters = keyBy(
  ActivityColumnDefinitions.filter((x) => x.filterable).map(createColumnFilter),
  ({ id }) => id
)
