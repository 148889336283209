import { sortBy, sumBy } from 'lodash'
import { isNotNullOrUndefined } from 'shared/guards'
import {
  IBalanceDetailResponseValueItem,
  IForeignCurrency
} from '../../../store/balancesApi/IBalanceDetailResponse'

export interface ICashBalanceDetails {
  cashBalanceType?: string // aggregated group label
  accountKey?: string // individual label
  isKeySelected?: boolean
  settelmentDateBalance?: number // aggregated or individual value
  pendingActivity?: number // aggregated or individual value
  tradeDateBalance?: number // aggregated or individual value
  multiMarginDetails?: ICashBalanceDetails[] // nested details
  foreignCurrency?: IForeignCurrency[]
}

const getCashBalanceDetails = (
  multiMarginDetails: IBalanceDetailResponseValueItem[],
  tdbSdbProperties: IBalanceDetailResponseValueItem,
  selectedAccountKey?: string
): ICashBalanceDetails => {
  const [tdb, sdb] = Object.values(tdbSdbProperties) as number[]
  const [tdbKey, sdbKey] = Object.keys(
    tdbSdbProperties
  ) as (keyof IBalanceDetailResponseValueItem)[]
  const multimargindetailsSorted = sortBy(multiMarginDetails, [
    (detail) => !detail.isprimary
  ])

  return {
    settelmentDateBalance: sdb,
    pendingActivity: tdb - sdb,
    tradeDateBalance: tdb,
    multiMarginDetails: multimargindetailsSorted.map((item) => {
      const { key } = item
      const tdbmm = item[tdbKey] as number
      const sdbmm = item[sdbKey] as number

      return {
        accountKey: key,
        isKeySelected: selectedAccountKey != null && selectedAccountKey === key,
        settelmentDateBalance: sdbmm,
        pendingActivity: tdbmm - sdbmm,
        tradeDateBalance: tdbmm
      }
    })
  }
}

export const getModalData = (
  rowData?: IBalanceDetailResponseValueItem,
  selectedAccountKey?: string
) => {
  const {
    multimargindetails = [],
    cashbalance = 0,
    sdcashbalance = 0,
    margincredit = 0,
    sdmargincredit = 0,
    shortcredit = 0,
    sdshortcredit = 0,
    dividendinterest_type9 = 0,
    sddividendinterest_type9 = 0,
    othercredit = 0,
    sdothercredit = 0,
    moneyaccountvalue = 0,
    foreigncurrency = [],
    ismultimargin
  } = rowData || {}

  const usdequivalentTotal = sumBy(
    foreigncurrency,
    ({ usdequivalent }) => usdequivalent ?? 0
  )

  const sdusdequivalentTotal = sumBy(
    foreigncurrency,
    ({ sdusdequivalent }) => sdusdequivalent ?? 0
  )

  const addRest = (tdbSdbProperties: IBalanceDetailResponseValueItem) =>
    getCashBalanceDetails(
      multimargindetails,
      tdbSdbProperties,
      selectedAccountKey
    )

  const cashBalanceConfig = [
    {
      cashBalanceType: 'Cash (Type 1 ) Balance',
      cashBalanceValue: { cashbalance, sdcashbalance },
      required: true
    },
    {
      cashBalanceType: 'Margin (Type 2) Credit Balance',
      cashBalanceValue: { margincredit, sdmargincredit },
      required: true
    },
    {
      cashBalanceType: 'Short (Type 3) Balance',
      cashBalanceValue: { shortcredit, sdshortcredit }
    },
    {
      cashBalanceType: 'Accumulated Dividends and Interest (Type 9)',
      cashBalanceValue: {
        dividendinterest_type9,
        sddividendinterest_type9
      }
    },
    {
      cashBalanceType: 'Bank Deposit & Non Core Money funds',
      nickName: 'nonCoreMoney'
    },
    {
      cashBalanceType: 'Other',
      cashBalanceValue: { othercredit, sdothercredit }
    },
    {
      cashBalanceType: 'Foreign Currency (USDE)',
      nickName: 'foreignCurrency'
    }
  ]

  const data: (ICashBalanceDetails | null)[] = cashBalanceConfig.map((item) => {
    const { cashBalanceType, cashBalanceValue = {}, nickName, required } = item
    // special treatment for nonCoreMoney
    if (nickName && nickName === 'nonCoreMoney') {
      return {
        cashBalanceType,
        settelmentDateBalance: undefined,
        pendingActivity: undefined,
        tradeDateBalance: moneyaccountvalue
      }
    }
    // special treatment for foreignCurrency
    if (nickName && nickName === 'foreignCurrency') {
      return foreigncurrency.length ||
        [usdequivalentTotal, sdusdequivalentTotal].some((val) => val !== 0)
        ? {
            cashBalanceType,
            settelmentDateBalance: sdusdequivalentTotal,
            pendingActivity: usdequivalentTotal - sdusdequivalentTotal,
            tradeDateBalance: usdequivalentTotal,
            foreignCurrency: foreigncurrency
          }
        : null
    }
    // special treatment for all zero balance values
    if (
      !ismultimargin &&
      !required &&
      !Object.values(cashBalanceValue).some((val) => val !== 0)
    ) {
      return null
    }
    // ...and the rest
    return {
      cashBalanceType,
      ...addRest(cashBalanceValue)
    }
  })

  return data.filter(isNotNullOrUndefined)
}
