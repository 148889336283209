import {
  ContextualMenuItemType,
  IconButton,
  IContextualMenuItem,
  Stack
} from '@fluentui/react'
import { useRdot360Navigation } from 'modules/Advisory/modules/Rdot360/shared/useRdot360navigation'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { usePaymentHub } from 'shared/hooks/usePaymentHub'
import { useUserSettings } from 'shared/hooks/useUserSettings'
import { accountContextUpdateActions } from 'store/context/account'
import { clientContextUpdateActions } from 'store/context/client'
import { RdotUserRoleEnum } from 'store/user/rdotUser'
import { useRdotUser } from 'store/user/useRdotUser'
import { IAccount } from '../../api/account.types'
import { navigationActions } from '../../store/ui/actions'
import { marginRatePanelActions } from '../MarginRateAdjustment/store/marginRatePanel'
import { relatedPartyPanelActions } from '../RelatedPartyPanel/store'

export interface IAccountMenuContainerProps {
  account: IAccount
  addAccountToRelatedPartyLaunchParams?: boolean
}

export const AccountMenuContainer: React.FC<IAccountMenuContainerProps> = ({
  account,
  addAccountToRelatedPartyLaunchParams
}) => {
  const { navigateToClientDashboard } = useRdot360Navigation()

  const dispatch = useDispatch()
  const {
    CustodyAccount,
    CustodianCode,
    CustodianType,
    id: accountId = '',
    accountId: uniqueId = ''
  } = account
  const tamaracAccountId = account?.VendorTamarac?.UploadAccountId

  const launchWealthscape = () =>
    dispatch(
      navigationActions.launchWealthscape(
        `account/profile?AcctNum=${CustodyAccount}`
      )
    )
  const launchTamarac = () =>
    dispatch(
      navigationActions.launchConfigApplication({
        id: 'tamarac',
        postLoginRedirectPath: `#/?accountId=${tamaracAccountId}`
      })
    )

  const openRelatedPartyPanel = () => {
    dispatch(
      relatedPartyPanelActions.openPanel({
        idType: 'accountId',
        id: uniqueId,
        displayName: CustodyAccount || 'Account',
        launchParameters: addAccountToRelatedPartyLaunchParams
          ? { sAcc: accountId }
          : undefined
      })
    )
  }

  const onClickMarginRateAdjustment = useCallback(() => {
    account != null &&
      dispatch(marginRatePanelActions.open(account?.householdId))
  }, [account, dispatch])

  const { setHousehold } = useRdot360Context()
  const navigate = useNavigate()
  const { roles } = useRdotUser()
  const { enablePreviewFeatures } = useUserSettings()
  const { navigateToTransfers, showChecks, showCreate, showEFT, showJNL } =
    usePaymentHub()

  const navigateToRdot360 = useCallback(() => {
    const { householdId, id } = account
    if (!householdId || !id) {
      return
    }
    setHousehold({
      householdId,
      selectionType: 'account',
      selectionIds: [id]
    })
    navigateToClientDashboard()
  }, [account, navigateToClientDashboard, setHousehold])

  const filterMenuItemByRoles = useCallback(
    (allowedRoles: RdotUserRoleEnum[]) => {
      return allowedRoles.some((role) => roles?.includes(role))
    },
    [roles]
  )
  const navigateToClientVerify = useCallback(() => {
    dispatch(clientContextUpdateActions.success(undefined))
    dispatch(accountContextUpdateActions.success([account]))

    navigate('/clientverification')
  }, [account, dispatch, navigate])

  const isNFS = CustodianCode === 'NFS'

  const showClientVerify = useCallback(() => {
    return (
      isNFS &&
      filterMenuItemByRoles([
        RdotUserRoleEnum.Advisory_Feature_ClientVerification
      ])
    )
  }, [filterMenuItemByRoles, isNFS])

  return (
    <Stack
      horizontal={true}
      tokens={{ childrenGap: 10 }}
      styles={{
        root: {
          alignItems: 'center'
        }
      }}
    >
      <IconButton
        title="Account Menu"
        ariaLabel="Menu"
        disabled={false}
        checked={false}
        menuIconProps={{ iconName: 'MoreVertical' }}
        menuProps={{
          shouldFocusOnMount: true,
          items: [
            {
              key: 'navigation',
              itemType: ContextualMenuItemType.Header,
              text: 'Navigation'
            },
            isNFS && {
              key: 'nfs-account-summary',
              onClick: () => {
                launchWealthscape()
              },
              text: 'Wealthscape Account Summary'
            },
            isNFS && {
              key: 'margin-rate-request-tool',
              onClick: () => {
                onClickMarginRateAdjustment()
              },
              text: 'Margin Rate Adjustment'
            },
            !!tamaracAccountId && {
              key: 'tamarac',
              onClick: () => {
                launchTamarac()
              },
              text: 'Tamarac Portfolio'
            },
            CustodianType !== 'External' && {
              key: 'related-parties',
              onClick: () => {
                openRelatedPartyPanel()
              },
              text: 'Related Parties'
            },
            {
              key: 'launch-client-dashboard',
              onClick: navigateToRdot360,
              text: 'Client Dashboard'
            },
            showClientVerify() && {
              key: 'launch-client-verification',
              onClick: navigateToClientVerify,
              text: 'One Time PIN Verification'
            },
            enablePreviewFeatures &&
              showCreate && {
                key: 'transfers',
                text: 'Transfers',
                subMenuProps: {
                  items: [
                    {
                      key: 'transfers-wire',
                      text: 'Create a new Wire',
                      onClick: () => navigateToTransfers(account, 'wire')
                    },
                    showEFT && {
                      key: 'transfers-eft',
                      text: 'Create a new EFT',
                      onClick: () => navigateToTransfers(account, 'eft')
                    },
                    showJNL && {
                      key: 'transfers-journal',
                      text: 'Create a new Journal',
                      onClick: () => navigateToTransfers(account, 'journal')
                    },
                    showChecks && {
                      key: 'transfers-check',
                      text: 'Create a new Check',
                      onClick: () => navigateToTransfers(account, 'check')
                    }
                  ].filter((t) => t)
                }
              }
          ].filter((x) => x) as IContextualMenuItem[]
        }}
      />
    </Stack>
  )
}
