import { FormattedDate, FormattedNumber, FormattedTime } from 'react-intl'

type Notation = 'compact' | 'standard' | 'scientific' | 'engineering'

const defaultPlaceholder = '--'

export const Currency: React.FC<{
  value?: number
  notation?: Notation
  currency?: string
  currencySign?: 'standard' | 'accounting'
  fractionDigits?: number
  placeholder?: string
}> = ({
  value,
  notation,
  fractionDigits,
  currency = 'USD',
  currencySign = 'standard',
  placeholder = defaultPlaceholder
}) => {
  if (value == null) {
    return <>{placeholder}</>
  }
  const defaultFractionDigits = value === 0 ? 0 : 2
  const decimals =
    fractionDigits != null ? fractionDigits : defaultFractionDigits

  return (
    <FormattedNumber
      value={value}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currency={currency}
      currencySign={currencySign}
      notation={notation}
      maximumFractionDigits={decimals}
      minimumFractionDigits={decimals}
    />
  )
}

export const USD: React.FC<{
  value?: number
  notation?: Notation
  currencySign?: 'standard' | 'accounting'
  fractionDigits?: number
  placeholder?: string
}> = ({
  value,
  notation,
  fractionDigits,
  currencySign = 'standard',
  placeholder = defaultPlaceholder
}) => {
  const currencyProps = {
    value,
    notation,
    fractionDigits,
    currency: 'USD',
    currencySign,
    placeholder
  }
  return <Currency {...currencyProps} />
}

export const Percentage: React.FC<{
  value?: number
  placeholder?: string
}> = ({ value, placeholder = defaultPlaceholder }) => {
  if (value == null) {
    return <>{placeholder}</>
  }
  const fractionDigits = value === 0 ? 0 : 2

  return (
    <>
      <FormattedNumber
        value={value}
        maximumFractionDigits={fractionDigits}
        minimumFractionDigits={fractionDigits}
      />
      %
    </>
  )
}

export const DateTime: React.FC<{
  value?: string | Date
  placeholder?: string
  showTime?: boolean
}> = ({ value, showTime, placeholder = defaultPlaceholder }) => {
  if (value == null) {
    return <>{placeholder}</>
  }
  return (
    <>
      <FormattedDate
        day="2-digit"
        month="2-digit"
        year="numeric"
        value={new Date(value)}
      />
      {showTime && (
        <>
          {' '}
          <FormattedTime value={new Date(value)} />
        </>
      )}
    </>
  )
}
