import {
  Dialog,
  Icon as FluentIcon,
  IDialogContentStyles,
  Link
} from '@fluentui/react'
import { buttonStyles } from 'modules/Advisory/modules/Rdot360/components/shared/Buttons'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { useCallback, useMemo, useRef, useState } from 'react'
import { getFileSize } from '../../shared/functions'
import { useSecureMessagesState } from '../../shared/hooks'

const dialogContentStyles: Partial<IDialogContentStyles> = {
  title: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#13272B',
    padding: '18px 30px'
  },
  header: {
    borderBottom: '1px solid rgba(0,0,0,0.1)'
  },
  content: { width: '550px' }
}

const allowedFileTypes = [
  '.pdf',
  '.xls',
  '.xlsx',
  '.csv',
  '.doc',
  '.docx',
  '.png',
  '.jpg',
  '.jpeg',
  '.tif',
  '.pages',
  '.txt',
  '.log',
  '.ppt',
  '.pptx',
  '.numbers',
  '.heic'
]

const fileSizeLimit = 104857600
const isValidFileSize = (size: number) => size <= fileSizeLimit
const isValidFileType = (fileName: string) => {
  const extension = fileName.split('.').pop()?.toLowerCase()
  if (!extension) {
    return false
  }

  return allowedFileTypes.includes(`.${extension}`)
}

export interface IAttachFileDialogProps {
  onChange: (attachments: File[]) => void
}

export const AttachFileDialog: React.FC<IAttachFileDialogProps> = ({
  onChange
}) => {
  const { showAttach, setShowAttach } = useSecureMessagesState()

  const inputFilesRef = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<File[]>([])
  const validFiles = useMemo(
    () =>
      files.every((f) => isValidFileSize(f.size) && isValidFileType(f.name)),
    [files]
  )

  const handleDoneClick = useCallback(() => {
    onChange(files)
    setShowAttach(false)
    setFiles([])
  }, [files, onChange, setShowAttach])

  const handleDismiss = useCallback(() => {
    setShowAttach(false)
    setFiles([])
  }, [setShowAttach])

  return (
    <Dialog
      hidden={!showAttach}
      modalProps={{
        isBlocking: true
      }}
      dialogContentProps={{
        title: 'Attach File',
        styles: dialogContentStyles
      }}
      minWidth={'550px'}
      onDismiss={handleDismiss}
    >
      <div css={{ padding: '17px 0' }}>
        <div
          css={(theme) => ({
            backgroundColor: '#F6FBFF',
            border: `${theme.colors.tertiaryGray4} 1px dashed`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '35px 55px',
            gridRowGap: 15,
            marginBottom: '10px'
          })}
        >
          <Icon type="FileUpload" height={40} width={40} />
          <div>
            <Link onClick={() => inputFilesRef.current?.click()}>Browse</Link>{' '}
            to select files
          </div>
          <div>
            Only {allowedFileTypes.join(', ')} files with max size of{' '}
            {getFileSize(fileSizeLimit)}.
          </div>
        </div>
        <div>
          <input
            accept={allowedFileTypes.join(',')}
            css={{ display: 'none' }}
            multiple={true}
            onChange={(e) => {
              if (e.target.files) {
                setFiles([...files, ...e.target.files])
              }
            }}
            ref={inputFilesRef}
            type="file"
          />
          {files.map((f, i) => (
            <div
              key={f.name}
              css={(theme) => ({
                borderBottom: `${theme.colors.tertiaryGray4} 1px solid`,
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '10px',
                marginBottom: '10px'
              })}
            >
              <div
                style={{
                  maxWidth: '300px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
                title={f.name}
              >
                {f.name}
              </div>
              <div style={{ display: 'flex', gap: '7px' }}>
                {isValidFileSize(f.size) && isValidFileType(f.name) ? (
                  getFileSize(f.size)
                ) : (
                  <>
                    <Icon
                      type="Exclamation"
                      height={16}
                      width={15}
                      color="#FF4733"
                      viewBox="0 0 15 16"
                    />
                    <div style={{ color: '#FF4733' }}>
                      {isValidFileType(f.name)
                        ? 'File exceeds size limit'
                        : 'File format is invalid'}
                    </div>
                  </>
                )}
                <FluentIcon
                  iconName="Cancel"
                  title="Remove"
                  styles={{
                    root: {
                      cursor: 'pointer',
                      display: 'block',
                      backgroundColor: '#eaeaea',
                      borderRadius: '15px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      padding: '3px',
                      selectors: {
                        ':hover': {
                          backgroundColor: '#dadada',
                          color: '#494847'
                        }
                      }
                    }
                  }}
                  onClick={() => {
                    const filesCopy = [...files]
                    filesCopy.splice(i, 1)
                    setFiles(filesCopy)
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          css={[buttonStyles.secondary, { width: '100px' }]}
          onClick={handleDismiss}
        >
          Cancel
        </button>
        <button
          css={[buttonStyles.primary, { width: '100px', marginLeft: 16 }]}
          disabled={!files.length || !validFiles}
          onClick={handleDoneClick}
        >
          Attach
        </button>
      </div>
    </Dialog>
  )
}
