import { css } from '@emotion/react'
import { Header, Row } from '@tanstack/react-table'
import { IClosedLotSummaryItem } from 'modules/Advisory/modules/Rdot360/store/types'
import React, { useMemo } from 'react'
import { GainLossBody, GainLossFooter, GainLossHeading } from '../GainLossTable'

const getClasses = () => ({
  printTableContainer: css({
    breakAfter: 'page',
    marginTop: 20
  }),
  printTableHeader: css({
    '& table': {
      marginBottom: '-3px'
    },
    '& span.column-not-sorted': {
      display: 'none'
    },
    '& th': {
      paddingLeft: '10px!important'
    },
    '& th:first-child': {
      paddingLeft: '0!important'
    },
    '& td th:nth-of-type(1)': {
      display: 'none'
    },
    '& td th:nth-of-type(2)': {
      paddingLeft: 10
    }
  }),
  printTableBody: css({
    '& tr': {
      pageBreakInside: 'avoid'
    },
    '& td td:nth-of-type(1)': {
      display: 'none'
    },
    '& td td:nth-of-type(2)': {
      paddingLeft: 10
    },
    '& mark.highlight': {
      backgroundColor: 'transparent',
      fontWeight: 'inherit !important',
      color: 'inherit !important'
    }
  }),
  printTableTotals: css({
    '& table': {
      position: 'static'
    },
    '& td td:nth-of-type(1)': {
      display: 'none'
    },
    '& td td:nth-of-type(2)': {
      paddingLeft: 10
    }
  }),
  headerPadding: css({
    padding: '20px 0'
  })
})

interface IPrintTableProps {
  headers: Header<IClosedLotSummaryItem, unknown>[]
  rows: Row<IClosedLotSummaryItem>[]
}

export const PrintTable: React.FC<IPrintTableProps> = ({ headers, rows }) => {
  const classes = useMemo(() => getClasses(), [])
  return (
    <div css={classes.printTableContainer}>
      <table>
        <thead>
          <tr>
            <td>
              <div css={classes.headerPadding}>&nbsp;</div>
            </td>
          </tr>
          <tr css={classes.printTableHeader}>
            <td>
              <GainLossHeading headers={headers} />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr css={classes.printTableBody}>
            <td>
              <GainLossBody rows={rows} />
            </td>
          </tr>
          <tr css={classes.printTableTotals}>
            <td>
              <GainLossFooter headers={headers} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
